import React from "react";
import PropTypes from "prop-types";

import ErrorResourceProvider from "contexts/ErrorResource";

import ApplicationErrorRender from "./ApplicationErrorRender";

const ApplicationErrorManagement = ({ children }) => {
    return (
        <ErrorResourceProvider>
            <ApplicationErrorRender>{children}</ApplicationErrorRender>
        </ErrorResourceProvider>
    );
};

ApplicationErrorManagement.propTypes = {
    children: PropTypes.node.isRequired
};

export default ApplicationErrorManagement;
