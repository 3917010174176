import React, { memo } from "react";
import PropTypes from "prop-types";
import { colors } from "common/styles/config";
import * as S from "./Spinner.styled";
const generateCssBallList = (count, diameter, time) => {
  return [...new Array(count)].map((item, index) => {
    const temp = index / count * (Math.PI * 2);
    const left = `${Math.cos(temp) * diameter}px`;
    const top = `${Math.sin(temp) * diameter}px`;
    const delay = time / count * (count - index);
    return {
      left,
      top,
      animationDuration: `${time}s`,
      animationTimingFunction: "ease-in",
      animationDelay: `${delay}s`,
      animationIterationCount: "infinite"
    };
  });
};
const Spinner = ({
  ballsCount,
  diameter,
  time,
  smallBallColor,
  ballColor
}) => {
  const ballsList = generateCssBallList(ballsCount, diameter, time);
  return <S.SpinnerContainer className="spinner">
            {ballsList.map((item, index) => <S.SpinnerBall key={index} style={{
      ...item
    }} backgroundColor={ballColor} backgroundSmallColor={smallBallColor} />)}
        </S.SpinnerContainer>;
};
Spinner.defaultProps = {
  ballsCount: 8,
  diameter: 10,
  time: 0.7,
  smallBallColor: colors.mainBlue,
  ballColor: colors.mainBlueHover
};
Spinner.propTypes = {
  ballsCount: PropTypes.number,
  diameter: PropTypes.number,
  time: PropTypes.number,
  smallBallColor: PropTypes.string,
  ballColor: PropTypes.string
};
export default memo(Spinner);