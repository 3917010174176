export const PRIMARY = "primary";
export const ACCENT = "accent";
export const ACCENT_TEXT = "accentText";
export const FILTER = "filter";
export const NEUTRAL = "neutral";
export const UPLOAD = "upload";
export const PRIMARY_TEXT = "primaryText";
export const PRIMARY_TEXT_CUSTOM = "primaryTextCustom";
export const PRIMARY_TEXT_BORDERED = "primaryTextBordered";

export const OUTLINE_PRIMARY = "outlinePrimary";
export const OUTLINE_SECONDARY = "outlineSecondary";
