import React from "react";

import { useErrorResourceContext } from "contexts/ErrorResource/utils";

import { isPresentValue } from "common/utils";

import ServerError from "modules/ServerError";

const ApplicationErrorRender = ({ children }) => {
    const { resourceError } = useErrorResourceContext();
    const hasError = isPresentValue(resourceError);

    const content = hasError ? <ServerError {...resourceError} /> : children;

    return content;
};

export default ApplicationErrorRender;
