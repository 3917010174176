import { vendorFormatterFromApi } from "../vendors";
import { memberArrayFormatterFromApi, metaWOPagesFormatterFromApi } from "../common";

export const bulkJobTargetPriceFormatter = ({
    bestVendor,
    book,
    targetPrice,
    bestOffer,
    additionalProfit
}) => {
    return {
        vendorId: bestVendor.id,
        vendorName: bestVendor.name,
        isbn13: book.isbn13,
        targetPrice,
        bestOffer,
        additionalProfit,
        sellLink:
            bestVendor?.id && book?.isbn13
                ? `${process.env.NEXT_PUBLIC_API_URL}exits/sell/${bestVendor.id}/${book.isbn13}`
                : null
    };
};

export const bulkJobFormatterFromApi = (data) => ({
    id: data.id,
    fetched: data.fetched,
    createdOn: data.createdOn,
    queued: data.queued,
    total: data.total,
    vendors: Object.keys(data.vendors).map((key) => vendorFormatterFromApi(data.vendors[key])),
    targetPriceProvided: data.targetPriceProvided
});

export const bulkJobTargetPriceFormatterFromApi = (data) => ({
    data: memberArrayFormatterFromApi(data).map(bulkJobTargetPriceFormatter),
    ...metaWOPagesFormatterFromApi(data)
});
