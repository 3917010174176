import React from "react";
import Head from "next/head";
import PropTypes from "prop-types";

const BookstoresByStateLandingSeo = ({ stateName }) => (
    <Head>
        <title key="title">
            {`${stateName} College Bookstore - Campus Bookstores in ${stateName} | BookScouter.com`}
        </title>
        <meta
            name="description"
            key="description"
            content={
                // eslint-disable-next-line max-len
                `The list of college bookstores in ${stateName}. Whether you're looking for ${stateName} State University bookstore or checking where your campus bookstore in ${stateName} is located, you're on the right page.`
            }
        />
    </Head>
);

export default BookstoresByStateLandingSeo;

BookstoresByStateLandingSeo.propTypes = {
    stateName: PropTypes.string
};
