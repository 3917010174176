import React from "react";
import PropTypes from "prop-types";
import * as S from "../../MobileScanner.styled";
const BackButtonComponent = ({
  onClick
}) => {
  return <S.BackButton onClick={onClick}>
            <S.Icon />
            Back
        </S.BackButton>;
};
BackButtonComponent.propTypes = {
  onClick: PropTypes.func
};
export default BackButtonComponent;