import React from "react";
import Head from "next/head";

import * as PATH from "common/constants/pathRoutes";
import { VIDEO_PRO_PAGE } from "common/constants/youtubeVideoids";

export const ProSeo = () => (
    <Head>
        <title key="title">{"Books Retail Arbitrage & Reselling Tools | BookScouter.com"}</title>
        <link
            rel="canonical"
            key="canonical"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.PRO}`}
        />
        <meta
            name="description"
            key="description"
            content={
                "Did you know you can make money flipping books? BookScouter Pro tools will " +
                "help you to find books retail arbitrage deals, identify high value books for " +
                "reselling, check pricing history, track your inventory, and many more."
            }
        />
        <meta
            property="og:title"
            key="og:title"
            content={"BookScouter Pro - Books Retail Arbitrage & Reselling Tools"}
        />
        <meta
            property="og:description"
            key="og:description"
            content={
                "Did you know you can make money flipping books? " +
                "BookScouter Pro tools will help you to find books retail arbitrage deals, " +
                "identify high value books for reselling, check pricing history, " +
                "track your inventory, and many more."
            }
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta
            property="og:image"
            key="og:image"
            content={`https://i.ytimg.com/vi/${VIDEO_PRO_PAGE}/maxresdefault.jpg`}
        />
        <meta
            property="og:url"
            key="og:url"
            content={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.PRO}`}
        />
    </Head>
);
