import { isPresentValue } from "common/utils";

import { SET_BREADCRUMB_LINKS, RESET_BREADCRUMB_LINKS } from "./constants";

export const initState = {
    breadcrumbLinks: []
};

export const setLazyInitState = ({ breadcrumbLinks }) => {
    return {
        ...initState,
        ...(isPresentValue(breadcrumbLinks) && { breadcrumbLinks })
    };
};

export const reducer = (state, { type, payload }) => {
    switch (type) {
        case SET_BREADCRUMB_LINKS:
            return { ...state, breadcrumbLinks: payload };
        case RESET_BREADCRUMB_LINKS:
            return { ...state, breadcrumbLinks: initState.breadcrumbLinks };

        default:
            return { ...state };
    }
};
