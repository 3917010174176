import format from "date-fns/format";

import { isPresentValue } from "common/utils";

import { memberArrayFormatterFromApi } from "../common";

const DATE_FORMAT = "MM/dd/yy";
const PRO_STATUS = "ACTIVE";

export const loginFormatterToApi = ({ email, password }) => ({
    auth: false,
    username: email,
    password,
    remember: true
});

export const linkLoginFormatterToApi = ({ isLoginLink, email, hash, expires }) => {
    return {
        auth: false,
        login_link: isLoginLink,
        user: email,
        hash,
        expires
    };
};

export const loginFormatterFromApi = () => ({
    isAuth: true
});

export const resetPassFormatterToApi = ({ email }) => ({
    email: email
});

export const getAuthUserFormatterFromApi = ({ user }) => {
    return {
        isAuth: isPresentValue(user),
        user: isPresentValue(user) ? userDataFormatterFromApi(user) : {}
    };
};

export const userDataFormatterToApi = (user) => ({
    currentCollegeStudent: user.currentCollegeStudent,
    firstName: user.firstName,
    lastName: user.lastName,
    settings: user.initialUserApiSettings.map((item) => {
        if (item.setting === "newsletter") {
            return { ...item, value: user.newsletter };
        }

        return { ...item };
    })
});

export const userDataFormatterFromApi = (user) => ({
    createdOn: user.created,
    currentCollegeStudent: user.currentCollegeStudent,
    email: user.email,
    firstName: user.firstName,
    id: user.id,
    lastLogin: user.lastLogin,
    lastName: user.lastName,
    proStatus: user.proStatus,
    status: user.status,
    verified: user.verified,
    userSettings: userSettingsFormatterFromApi(user),
    initialUserApiSettings: user.settings,
    get isProUser() {
        return this.proStatus === PRO_STATUS;
    }
});

export const signUpFormatterToApi = ({ email, password, registerNewsletter }) => ({
    email: email,
    password: password,
    settings: [{ setting: "newsletter", value: registerNewsletter ? "subscribe" : "unsubscribe" }]
});

export const userSubscriptionsFormatterFromApi = (data) => ({
    cardLast4: data.cardLast4,
    cardType: data.cardType.toLowerCase().replace(" ", "-"),
    memberSince: format(new Date(data.memberSince * 1000), DATE_FORMAT),
    lastPayment: format(new Date(data.currentPeriodStart * 1000), DATE_FORMAT),
    currentPeriodEnd: format(new Date(data.currentPeriodEnd * 1000), DATE_FORMAT),
    status: data.status
});

export const userSubscriptionsHistoryFormatterFromApi = (data) =>
    memberArrayFormatterFromApi(data).map((item) => ({
        message: item.message,
        receivedOn: format(new Date(item.receivedOn), DATE_FORMAT)
    }));

export const userSettingsFormatterFromApi = (data) => {
    return data.settings.reduce(
        (acc, curr) => {
            if (curr.setting === "user_sell_vendors") {
                const parsedValue = JSON.parse(curr.value);

                return {
                    ...acc,
                    userSellVendors: curr.value ? parsedValue.map((id) => parseInt(id, 10)) : []
                };
            }

            if (curr.setting === "user_vendors_grid_view") {
                const parsedValue = JSON.parse(curr.value);

                return {
                    ...acc,
                    userVendorsGridView: curr.value ? parsedValue.map((id) => parseInt(id, 10)) : []
                };
            }

            return { ...acc, [curr.setting]: curr.value };
        },
        {
            userSellVendors: [],
            userVendorsGridView: [],
            deals_max_price: "999",
            deals_min_price: "20",
            deals_min_profit: "20",
            deals_min_pct: "5",
            deals_days: "7",
            deals_sell_vendors: null,
            deals_buy_vendors: null
        }
    );
};

const getMetaSettingsFields = (userId, setting) => ({
    "@id": `/v4/user_settings/user=${userId};setting=${setting}`,
    "@type": "UserSetting"
});

export const userSettingsFormatterToApi = ({ prevUserSettings, nextUserSettings }) => {
    const nextUserSettingsKeys = Object.keys(nextUserSettings);
    const updatedSettings = [...prevUserSettings.initialUserApiSettings];

    nextUserSettingsKeys.forEach((settingKey) => {
        const existingSettingIdx = updatedSettings.findIndex((item) => item.setting === settingKey);

        if (existingSettingIdx < 0) {
            updatedSettings.push({
                setting: settingKey,
                value: nextUserSettings[settingKey]
            });
        }

        updatedSettings[existingSettingIdx] = {
            ...updatedSettings[existingSettingIdx],
            setting: settingKey,
            value: nextUserSettings[settingKey],
            ...getMetaSettingsFields(prevUserSettings.id, settingKey)
        };
    });

    return {
        collegeMajor: prevUserSettings.collegeMajor,
        currentCollegeStudent: prevUserSettings.currentCollegeStudent,
        firstName: prevUserSettings.firstName,
        graduationDate: prevUserSettings.graduationDate,
        lastName: prevUserSettings.lastName,
        settings: updatedSettings
    };
};
