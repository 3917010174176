import { createContext, useContext } from "react";

import { assertDefined } from "common/utils";

export const AppContext = createContext(null);

export const useAppContext = () => {
    const context = useContext(AppContext);

    assertDefined(context);

    return context;
};
