import * as BOOK_TYPES from "./searchTypes";

// Main route pathes
export const MAIN = "/";
export const VENDORS = "/vendors";
export const PRO_TOOLS = "/tools";
export const PRO = "/pro";
export const SCHOLARSHIP = "/scholarship";
export const WIDGET = "/widget";
export const AFFILIATE_WIDGET = "/get-affiliate-widget";
export const API = "/api-tools";

// WordPress links
export const TIPS = "/tips";
export const BLOG = "/blog";

// Books functionality route pathes
export const SEARCH = "/search";
export const ADVANCED_SEARCH_PARAMS = "/advanced-search/params";
export const ADVANCED_SEARCH = "/advanced-search";
export const SELL = `/${BOOK_TYPES.SELL_STATE}`;
export const BUY = `/${BOOK_TYPES.BUY_STATE}`;
export const RENT = `/${BOOK_TYPES.RENT_STATE}`;

// Bookscouter information route pathes
export const PRESS = "/press";
export const TERMS = "/terms";
export const PRIVACY = "/privacy";
export const FAQ = "/faq";
export const BOOK = "/book";
export const BULK_COMPARISON = "/bulk-comparison";
export const AFFILIATES = "/affiliates";
export const COLLEGE_BOOKSTORES = "/college-bookstores";
export const AUTHOR = "/author";
export const AUTHORS = "/authors";
export const PUBLISHER = "/publisher";
export const PUBLISHERS = "/publishers";
export const CATEGORY = "/category";
export const CATEGORIES = "/categories";
export const REVIEWS = "/reviews";
export const RECENT = "/recent";
export const RECENT_SELL = `${RECENT}${SELL}`;
export const RECENT_BUY = `${RECENT}${BUY}`;
export const RECENT_RENT = `${RECENT}${RENT}`;

// Bookscouters user settings path
export const ACCOUNT = "/account";
export const ACCOUNT_PROFILE = `${ACCOUNT}/profile`;
export const ACCOUNT_VENDORS = `${ACCOUNT}/vendors`;
export const ACCOUNT_BILLING = `${ACCOUNT}/billing`;
export const ACCOUNT_SUBSCRIPTION = `${ACCOUNT}/subscription`;
export const WATCHLIST = "/watchlist";
export const WATCHLIST_SELL = `${WATCHLIST}${SELL}`;
export const WATCHLIST_BUY = `${WATCHLIST}${BUY}`;

// Bookscouters pro user tools
export const DEALS = "/deals";
export const BULK_LOOKUP = "/bulk-lookup";
export const BULK = "/bulk";
export const PRICE_HISTORY = "/historic";
export const HIGH_VALUE = "/high-value";

// Bookscouter modals
export const CONTACT = "/contact";
export const LOGIN = "/login";
export const REGISTER = "/register";
export const CHANGE_PASSWORD = "/change-password";
export const ACCOUNT_PASSWORD = `${ACCOUNT}/password`;

// Bookscouter files
export const FILES = "/files";

// route for ssr optimization with health check
export const HEALTH = "/health";
