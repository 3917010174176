import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";

import { useAuthContext, useAppContext } from "contexts";
import { gtmGetUser, isPresentValue } from "common/utils";
import * as PATH from "common/constants/pathRoutes";
import { getAuth, linkLoginAuth } from "contexts/Auth/actions";
import { fetchWatchlist, initRecentBooksCount } from "contexts/App/actions";

import ApplicationErrorManagement from "components/ApplicationErrorManagement";

import Header from "components/Header";
import Footer from "components/Footer";
import MobileScanner from "components/MobileScanner";

const AppLayout = ({ children }) => {
    const [state, dispatch] = useAuthContext();
    const [{ isScanning }, appDispatch] = useAppContext();
    const router = useRouter();

    const isChangePasswordRoute = useMemo(
        () => router.asPath.includes(PATH.CHANGE_PASSWORD),
        [router.asPath]
    );

    const { isAuth, user, isLoginPassed, afterAuthCallback } = state;

    const isMainPage = useMemo(
        () =>
            router.asPath === PATH.MAIN ||
            [
                PATH.BUY,
                PATH.RENT,
                PATH.LOGIN,
                PATH.REGISTER,
                PATH.CONTACT,
                PATH.CHANGE_PASSWORD
            ].some((route) => router.asPath === route),
        [router.asPath]
    );

    useEffect(() => {
        initRecentBooksCount({ dispatch: appDispatch });
    }, []);

    useEffect(() => {
        getAuth({ dispatch, isLoginPassed, afterAuthCallback });
        gtmGetUser({ user, isAuth });

        if (isAuth) {
            fetchWatchlist({ dispatch: appDispatch });
        }
    }, [isAuth]);

    useEffect(() => {
        // login with link for reset password
        if (isChangePasswordRoute) {
            const { user: userParam, hash, expires } = router.query;
            const data = {
                email: isPresentValue(userParam) ? decodeURIComponent(userParam) : "",
                hash: isPresentValue(hash) ? decodeURIComponent(hash) : "",
                expires: isPresentValue(expires) ? decodeURIComponent(expires) : "",
                isLoginLink: true
            };
            linkLoginAuth({ dispatch, data, history: router });
        }
    }, []);

    useEffect(() => {
        if (window.ga && window.ga.create) {
            window.ga("send", "pageview", { page: router.asPath });
            window.dataLayer.push({
                pageview: router.asPath
            });
        }
    });

    return isScanning ? (
        <MobileScanner />
    ) : (
        <>
            <Header isMainPage={isMainPage} />
            <ApplicationErrorManagement>{children}</ApplicationErrorManagement>
            <Footer />
        </>
    );
};

AppLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default AppLayout;
