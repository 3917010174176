import React from "react";
import Head from "next/head";

import * as PATH from "common/constants/pathRoutes";

export const ProToolsSeo = () => (
    <Head>
        <title key="title">
            {"Flipping Books Pro Tools - Books Retail Arbitrage & Reselling | BookScouter.com"}
        </title>
        <link
            rel="canonical"
            key="canonical"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.PRO_TOOLS}`}
        />
        <meta
            name="description"
            key="description"
            content={
                "Did you know you can make money flipping books? " +
                "BookScouter Pro tools will help you to find books retail arbitrage deals, " +
                "identify high value books for reselling, check pricing history, track your " +
                "inventory, and many more."
            }
        />
        <meta property="og:title" key="og:title" content={"BookScouter Pro Tools"} />
        <meta
            property="og:description"
            key="og:description"
            content={
                "Did you know you can make money flipping books? " +
                "BookScouter Pro tools will help you to find books retail arbitrage deals, " +
                "identify high value books for reselling, check pricing history, " +
                "track your inventory, and many more."
            }
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
        <meta
            property="og:url"
            key="og:url"
            content={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.PRO_TOOLS}`}
        />
    </Head>
);
