import format from "date-fns/format";

import { DEFAULT_BOOK_TITLE } from "common/utils";
import { BOOK_UNAVAILABLE_IMG } from "components/BookAsset/constants";

import { memberArrayFormatterFromApi } from "../common";

export const watchlistItemFormatterFromApi = (data) => ({
    book: {
        isbn10: data.book.isbn10,
        isbn13: data.book.isbn13,
        asin: data.book.asin,
        title: data.book.title ? data.book.title : DEFAULT_BOOK_TITLE,
        author: data.book.author ? data.book.author.join("; ") : "Unknown",
        publisher: data.book.publisher,
        img: data.book.image ? data.book.image.replace("_SL75_", "_SL300_") : BOOK_UNAVAILABLE_IMG,
        previewImg: data.book.image
            ? data.book.image.replace("_SL75_", "_SL500_")
            : BOOK_UNAVAILABLE_IMG,
        slug: data.book.slug,
        id: data.id
    },
    vendor: {
        id: data.bestOffer?.vendor.id,
        name: data.bestOffer?.vendor.name,
        slug: data.bestOffer?.vendor.slug,
        type: data.bestOffer?.vendor.type,
        iconPath: data.bestOffer?.vendor.slug
            ? `/images/logos/vendors/icon-${data.bestOffer.vendor.slug}.png`
            : null
    },
    bestPrice: data.bestOffer?.shipping
        ? (data.bestOffer?.price + data.bestOffer?.shipping).toFixed(2)
        : data.bestOffer?.price?.toFixed(2),
    isRefreshed: data.isRefreshed,
    needNotify: data.needNotify,
    notifyPrice: parseFloat(data.notifyThreshold),
    bookAddedDate:
        typeof data.book.publishedDate === "string"
            ? format(new Date(data.book.publishedDate), "MM/dd/yyyy")
            : null,
    priceCurrency: "$",
    // for custom notification card component data
    notificationSuccessMessage: null,
    notificationErrorMessage: null,
    priceSuccessMessage: null,
    priceErrorMessage: null,
    type: data.type
});

export const watchlistFormatterFromApi = (data) => ({
    data: memberArrayFormatterFromApi(data).map(watchlistItemFormatterFromApi),
    total: data["hydra:totalItems"]
});
