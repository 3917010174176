import {
    memberArrayFormatterFromApi,
    metaFormatterFromApi,
    metaWOPagesFormatterFromApi
} from "api/formatters/common";

import { isEmptyValue, isPresentValue } from "common/utils";
import { bookFormatterFromApi } from "../book";

export const categoryFormatterFromApi = ({ id = "", name = "", parent, slug }) => ({
    id,
    name,
    parent: isPresentValue(parent) ? categoryFormatterFromApi(parent) : null,
    get categorySlugName() {
        if (isEmptyValue(slug) && isEmptyValue(id)) {
            return "";
        }

        if (isEmptyValue(slug) && id) {
            return `-${name
                .toLowerCase()
                .replace(/,|\./g, "")
                .replace(/\//g, " ")
                .split(" ")
                .join("-")}`;
        }

        return slug;
    },
    get categoryRoute() {
        if (isEmptyValue(slug) && id) {
            return encodeURIComponent(`${id}${this.categorySlugName}`);
        }

        return encodeURIComponent(`${this.categorySlugName}`);
    },
    get categoryFullName() {
        return isPresentValue(this.parent) ? `${this.parent.name}: ${this.name}` : this.name;
    },
    get hasParent() {
        return isPresentValue(this.parent);
    }
});

export const categoriesFormatterFromApi = (data) => {
    return {
        data: memberArrayFormatterFromApi(data).map(categoryFormatterFromApi),
        ...metaWOPagesFormatterFromApi(data)
    };
};

export const categoriesBooksFromApi = (data) => {
    return {
        data: memberArrayFormatterFromApi(data).map(bookFormatterFromApi),
        ...metaFormatterFromApi(data)
    };
};
