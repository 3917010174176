import React from "react";
import Head from "next/head";

import * as PATH from "common/constants/pathRoutes";

export const TermsSeo = () => (
    <Head>
        <title key="title">{"Terms | BookScouter.com"}</title>
        <link
            rel="canonical"
            key="canonical"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.TERMS}`}
        />
        <meta
            name="description"
            key="description"
            content={"Read more about BookScouter's Terms and Conditions."}
        />
        <meta property="og:title" key="og:title" content={""} />
    </Head>
);
