import { toast } from "react-toastify";

import { APP } from "common/constants/notificationContainerTypes";

const addSuccessNotification = ({ msg = "", containerId = APP }) => {
    return toast.success(msg, { containerId });
};

const addErrorNotification = ({ msg = "", containerId = APP }) => {
    return toast.error(msg, { containerId });
};

const addWarningNotification = ({ msg = "", containerId = APP }) => {
    return toast.warn(msg, { containerId });
};

const addInfoNotification = ({ msg = "", containerId = APP }) => {
    return toast.info(msg, { containerId });
};

const updateNotification = (toastId, { msg = "", containerId = APP }) => {
    return toast.update(toastId, { render: msg, containerId });
};

const removeNotification = (toastId) => {
    return toast.dismiss(toastId);
};

export {
    addSuccessNotification,
    addErrorNotification,
    addWarningNotification,
    addInfoNotification,
    updateNotification,
    removeNotification
};
