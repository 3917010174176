import React from "react";
import Head from "next/head";

import * as PATH from "common/constants/pathRoutes";

export const VendorsListSeo = () => (
    <Head>
        <title key="title">
            {"Bookstores & Buyback Vendors - Websites To Sell Used Books | BookScouter.com"}
        </title>
        <link
            rel="canonical"
            key="canonical"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.VENDORS}`}
        />
        <meta
            name="description"
            key="description"
            content={
                "Compare textbook buyback prices from over 35 online companies with a single " +
                "search. Selling textbooks online pays you more than college bookstores. " +
                "BookScouter's buyback price comparison helps you sell your books for " +
                "the best price."
            }
        />
        <meta
            property="og:title"
            key="og:title"
            content={"Bookstores & Buyback Vendors - Websites To Sell Used Books | BookScouter.com"}
        />
        <meta
            property="og:description"
            key="og:description"
            content={
                "Compare textbook buyback prices from over 35 online companies with a " +
                "single search. " +
                "Selling textbooks online pays you more than college bookstores. " +
                "BookScouter's buyback price comparison helps you sell your books " +
                "for the best price."
            }
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
        <meta
            property="og:url"
            key="og:url"
            content={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.VENDORS}`}
        />
    </Head>
);
