import React from "react";
import Head from "next/head";

export const AdvancedSearchSeo = () => (
    <Head>
        <title key="title">Advanced Book Search by Title, Author, Publisher | BookScouter</title>
        <meta
            name="description"
            key="description"
            content={
                "Search a book by title, author, publisher, keyword, and publish year on " +
                "BookScouter." +
                " Make use of our non-ISBN search and identify books and their prices instantly!"
            }
        />
        <meta
            property="og:title"
            key="og:title"
            content={"Advanced Book Search by Title, Author, Publisher | BookScouter"}
        />
        <meta
            property="og:description"
            key="og:description"
            content={
                "Search a book by title, author, publisher, keyword, and publish year " +
                "on BookScouter." +
                " Make use of our non-ISBN search and identify books and their prices instantly!"
            }
        />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
    </Head>
);
