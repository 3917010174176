import React from "react";

import Head from "next/head";

export const CategoriesSeo = () => (
    <Head>
        <title key="title">
            Book categories: Most searched books by category | BookScouter.com
        </title>
        <meta
            name="description"
            key="description"
            content={
                "See the list of book categories you can search on BookScouter.com. " +
                "For your convenience, we've compiled a list of the most searched books " +
                "by category."
            }
        />
        <meta
            property="og:title"
            key="og:title"
            content={"Book categories: Most searched books by category | BookScouter.com"}
        />
        <meta
            property="og:description"
            key="og:description"
            content={
                "See the list of book categories you can search on BookScouter.com. " +
                "For your convenience, we've compiled a list of the most searched books " +
                "by category."
            }
        />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
    </Head>
);

export const AuthorsSeo = () => (
    <Head>
        <title key="title">Book authors: Most searched books by author | BookScouter.com</title>
        <meta
            name="description"
            key="description"
            content={
                "See the list of book authors you can search on BookScouter.com. " +
                "For your convenience, we've compiled a list of the most searched books " +
                "by the author."
            }
        />
        <meta
            property="og:title"
            key="og:title"
            content={"Book authors: Most searched books by author | BookScouter.com"}
        />
        <meta
            property="og:description"
            key="og:description"
            content={
                "See the list of book authors you can search on BookScouter.com. " +
                "For your convenience, we've compiled a list of the most searched books " +
                "by the author."
            }
        />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
    </Head>
);

export const PublishersSeo = () => (
    <Head>
        <title key="title">
            Book publishers: Most searched books by publisher | BookScouter.com
        </title>
        <meta
            name="description"
            key="description"
            content={
                "See the list of book publishers you can search on BookScouter.com. " +
                "For your convenience, we've compiled a list of the most searched books " +
                "by the publisher."
            }
        />
        <meta
            property="og:title"
            key="og:title"
            content={"Book publishers: Most searched books by publisher | BookScouter.com"}
        />
        <meta
            property="og:description"
            key="og:description"
            content={
                "See the list of book publishers you can search on BookScouter.com. " +
                "For your convenience, we've compiled a list of the most searched books " +
                "by the publisher."
            }
        />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
    </Head>
);
