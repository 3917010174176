import { DEFAULT_PAGE } from "components/Pagination/constants";

const isFilterDataExist = (data) => data && Object.values(data).find((facet) => facet.length);

export const facetsFormatterFromApi = (data) => {
    const filterData = isFilterDataExist(data["facets"]) ? data["facets"] : null;

    if (filterData) {
        const normalizedFilterData = {};

        Object.keys(filterData).forEach((key) => {
            normalizedFilterData[key] = [];

            filterData[key].forEach((item) => {
                if (item.key !== "") {
                    normalizedFilterData[key].push(item);
                }
            });
        });

        return normalizedFilterData;
    }

    return filterData;
};

export const memberArrayFormatterFromApi = (data) => data["hydra:member"] || [];

export const authorsFormatterFromApi = (value) => {
    return value && Array.isArray(value)
        ? value.join("; ")
        : value && typeof value === "object"
        ? Object.keys(value)
              .filter((key) => !key.includes("hydra"))
              .map((key) => value[key])
              .join("; ")
        : "Unknown";
};

export const metaFormatterFromApi = (data) => {
    const pageMatch = data["hydra:view"]["@id"].match(/page=(\d+)/);
    const itemsPerPageMatch = data["hydra:view"]["@id"].match(/itemsPerPage=(\d+)/);
    const nextPage = data["hydra:view"] ? data["hydra:view"]["hydra:next"] : null;
    const previousPage = data["hydra:view"] ? data["hydra:view"]["hydra:previous"] : null;

    return {
        meta: {
            page: pageMatch ? parseInt(pageMatch[1], 10) : DEFAULT_PAGE,
            itemsPerPage: itemsPerPageMatch ? parseInt(itemsPerPageMatch[1], 10) : 1,
            pages: data["hydra:view"]["hydra:last"]
                ? parseInt(data["hydra:view"]["hydra:last"].match(/page=(\d+)/)[1], 10)
                : null,
            count: data["hydra:totalItems"],
            after: new URLSearchParams(nextPage).get("after"),
            before: new URLSearchParams(previousPage).get("before")
        }
    };
};

export const metaWOPagesFormatterFromApi = (data) => {
    const pageMatch = data["hydra:view"]["@id"].match(/page=(\d+)/);

    return {
        meta: {
            page: pageMatch ? parseInt(pageMatch[1], 10) : DEFAULT_PAGE,
            withNextPage: data["hydra:view"]["hydra:next"] ? true : false,
            withPreviousPage: data["hydra:view"]["hydra:previous"] ? true : false,
            count: memberArrayFormatterFromApi(data).length || null
        }
    };
};
