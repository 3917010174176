import {
    ACCENT,
    ACCENT_TEXT,
    FILTER,
    NEUTRAL,
    PRIMARY,
    PRIMARY_TEXT,
    PRIMARY_TEXT_BORDERED,
    PRIMARY_TEXT_CUSTOM,
    UPLOAD
} from "common/constants/buttonTypes";
import { colors } from "common/styles/config";

export const spinnerColorsConfig = {
    [PRIMARY]: {
        ballColor: "#fff",
        smallBallColor: "#fff"
    },
    [ACCENT]: {
        ballColor: "#fff",
        smallBallColor: "#fff"
    },
    [ACCENT_TEXT]: {
        ballColor: colors.mainOrange,
        smallBallColor: colors.mainOrange
    },
    [FILTER]: { ballColor: "#696969", smallBallColor: "#8c8c8c" },
    [NEUTRAL]: {
        ballColor: "#3e5c6d",
        smallBallColor: "#8c8c8c"
    },
    [UPLOAD]: {
        ballColor: "#696969",
        smallBallColor: "#595959"
    },
    [PRIMARY_TEXT]: {
        ballColor: colors.mainBlue,
        smallBallColor: "#67bfcb"
    },
    [PRIMARY_TEXT_CUSTOM]: {
        ballColor: "#000000",
        smallBallColor: "#67bfcb"
    },
    [PRIMARY_TEXT_BORDERED]: {
        ballColor: "#67bfcb",
        smallBallColor: "#fff"
    }
};
