import React, { useState } from "react";
import PropTypes from "prop-types";

import { isPresentValue } from "common/utils";

import { ErrorResourceContext } from "./utils";

const ErrorResourceProvider = ({ children }) => {
    const [resourceError, setResourceError] = useState(null);

    const addResourceError = (error) => {
        const { status, message } = error;

        setResourceError({ statusCode: status, statusText: message });
    };

    const cleanUpResourceError = () => {
        if (isPresentValue(resourceError)) {
            setResourceError(null);
        }
    };

    const context = {
        resourceError,
        addResourceError,
        cleanUpResourceError
    };

    return (
        <ErrorResourceContext.Provider value={context}>{children}</ErrorResourceContext.Provider>
    );
};

ErrorResourceProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default ErrorResourceProvider;
