import format from "date-fns/format";

import { memberArrayFormatterFromApi, metaWOPagesFormatterFromApi } from "../common";

const shortenedBookFormatterFromApi = (item) => {
    const currency = "$";

    return {
        maxPrice: `${currency}${item.maxPrice.toFixed(2)}`,
        title: item.title,
        isbn13: item.isbn13,
        slug: item.book.slug,
        date: format(new Date(item.dateSeen), "MM/dd/yy")
    };
};

export const shortenedBooksFormatterFromApi = (data) => ({
    data: memberArrayFormatterFromApi(data).map(shortenedBookFormatterFromApi),
    ...metaWOPagesFormatterFromApi(data)
});
