import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";

import { assertDefined } from "common/utils";

import { getModalContent } from "./utils";

const Modal = dynamic(() => import("components/Modal"), { ssr: false });

export const ModalProvider = ({ children }) => {
    const [modalSettings, setModalSettings] = useState({});
    const [isModalHidden, setModalHidden] = useState(true);
    const [modalChildrenProps, setModalChildrenProps] = useState({});

    const {
        type,
        title,
        modalStyles,
        onCloseModalCb,
        children: modalChildren,
        childrenProps,
        ...restModalProps
    } = modalSettings;

    const isModalOpen = (value) => value === type;

    const onCloseModal = () => {
        setModalHidden(true);
        onCloseModalCb && onCloseModalCb();
    };

    const modalContent = useMemo(
        () =>
            getModalContent({
                type,
                children: modalChildren,
                childrenProps: {
                    ...childrenProps,
                    ...modalChildrenProps,
                    handleClose: onCloseModal
                }
            }),
        [modalSettings, modalChildrenProps]
    );

    const onModalAction = useCallback((modalConfig) => {
        setModalSettings(modalConfig);
        setModalHidden(false);
    }, []);

    return (
        <ModalContext.Provider
            value={{
                modalAction: onModalAction,
                setModalChildrenProps,
                isModalOpen
            }}>
            {!isModalHidden && (
                <Modal
                    title={title}
                    onClose={onCloseModal}
                    styles={modalStyles}
                    {...restModalProps}>
                    {modalContent}
                </Modal>
            )}
            {children}
        </ModalContext.Provider>
    );
};

ModalProvider.propTypes = {
    children: PropTypes.node
};

export const ModalContext = createContext(null);

export const useModalContext = () => {
    const context = useContext(ModalContext);

    assertDefined(context);

    return context;
};
