import React from "react";
import Head from "next/head";
import PropTypes from "prop-types";

export const AuthorSeo = ({ category }) => (
    <Head>
        <title key="title">
            {`${category.name} books - All books by ${category.name} author | BookScouter.com`}
        </title>
        <meta
            name="description"
            key="description"
            content={
                `See the best price to sell, buy, or rent books by ${category.name}.` +
                " BookScouter helps to compare book prices from 25+ online bookstores and 30+ " +
                "buyback vendors with a single search."
            }
        />
        <meta
            property="og:title"
            key="og:title"
            content={
                `${category.name} books - All books by ${category.name} author ` +
                "| BookScouter.com"
            }
        />
        <meta
            property="og:description"
            key="og:description"
            content={
                `See the best price to sell, buy, or rent books by ${category.name}.` +
                " BookScouter helps to compare book prices from 25+ online bookstores and 30+ " +
                "buyback vendors with a single search."
            }
        />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
    </Head>
);

export const PublisherSeo = ({ category }) => (
    <Head>
        <title key="title">
            {`${category.name} books - All books by ${category.name} publisher | BookScouter.com`}
        </title>
        <meta
            name="description"
            key="description"
            content={
                `See the best price to sell, buy or rent books by the ${category.name} ` +
                "publisher. BookScouter helps to compare book prices from 25+ online bookstores " +
                "and 30+ buyback vendors with a single search."
            }
        />
        <meta
            property="og:title"
            key="og:title"
            content={
                `${category.name} books - All books by ${category.name} publisher ` +
                "| BookScouter.com"
            }
        />
        <meta
            property="og:description"
            key="og:description"
            content={
                `See the best price to sell, buy or rent books by the ${category.name} ` +
                "publisher. BookScouter helps to compare book prices from 25+ online bookstores " +
                "and 30+ buyback vendors with a single search."
            }
        />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
    </Head>
);

export const CategorySeo = ({ category }) => (
    <Head>
        <title key="title">
            {`${category.name} books - All books related to ${category.name} | BookScouter.com`}
        </title>
        <meta
            name="description"
            key="description"
            content={
                `See the best price to sell, buy or rent books on the ${category.name} ` +
                "subject. BookScouter helps to compare book prices from 25+ online bookstores " +
                "and 30+ buyback vendors with a single search."
            }
        />
        <meta
            property="og:title"
            key="og:title"
            content={
                `${category.name} books - All books related to ${category.name} ` +
                "| BookScouter.com"
            }
        />
        <meta
            property="og:description"
            key="og:description"
            content={
                `See the best price to sell, buy or rent books on the ${category.name} ` +
                "subject. BookScouter helps to compare book prices from 25+ online bookstores " +
                "and 30+ buyback vendors with a single search."
            }
        />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
    </Head>
);

const propTypes = {
    category: PropTypes.object.isRequired
};

AuthorSeo.propTypes = propTypes;
PublisherSeo.propTypes = propTypes;
CategorySeo.propTypes = propTypes;
