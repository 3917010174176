import {
    FETCH_AUTH_SUCCESS,
    LOGIN_AUTH,
    LOGIN_AUTH_SUCCESS,
    LOGIN_AUTH_ERROR,
    LOGOUT_AUTH,
    LOGOUT_AUTH_SUCCESS,
    LOGOUT_AUTH_ERROR,
    SIGN_UP_AUTH,
    SIGN_UP_AUTH_SUCCESS,
    SIGN_UP_AUTH_ERROR,
    RESET_AUTH,
    RESET_AUTH_SUCCESS,
    RESET_AUTH_ERROR,
    UPDATE_USER_DATA,
    UPDATE_USER_DATA_SUCCESS,
    UPDATE_USER_DATA_ERROR,
    UPDATE_USER_PASSWORD,
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_PASSWORD_ERROR,
    RESET_LOGIN_PASSED,
    SET_USER_SETTINGS,
    SET_AFTER_AUTH_CALLBACK,
    FETCH_AUTH_ERROR
} from "./actionTypes";

export const initState = {
    isAuth: false,
    user: {},
    isLoading: true,
    isSettingsLoading: false,
    isPasswordReseted: false,
    isLoginPassed: false,
    afterAuthCallback: null
};

export const reducer = (state, { type, payload }) => {
    switch (type) {
        case FETCH_AUTH_SUCCESS:
            return { ...state, isLoading: false, ...payload };

        case FETCH_AUTH_ERROR:
            return { ...state, isLoading: false };

        case LOGIN_AUTH:
            return { ...state, isLoading: true };

        case LOGIN_AUTH_SUCCESS:
            return { ...state, isLoading: false, isLoginPassed: true, ...payload };

        case LOGIN_AUTH_ERROR:
            return { ...state, isLoading: false, isLoginPassed: false };

        case RESET_LOGIN_PASSED:
            return { ...state, isLoginPassed: false };

        case RESET_AUTH:
            return { ...state, isLoading: true };

        case RESET_AUTH_SUCCESS:
            return { ...state, isLoading: false, isPasswordReseted: true };

        case RESET_AUTH_ERROR:
            return { ...state, isLoading: false };

        case SIGN_UP_AUTH:
            return { ...state, isLoading: true };

        case SIGN_UP_AUTH_SUCCESS:
            return { ...state, isLoading: false, user: payload };

        case SIGN_UP_AUTH_ERROR:
            return { ...state, isLoading: false };

        case LOGOUT_AUTH:
            return { ...state, isLoading: true };

        case LOGOUT_AUTH_SUCCESS:
            return {
                ...initState
            };

        case LOGOUT_AUTH_ERROR:
            return { ...state, isLoading: false };

        case UPDATE_USER_DATA:
            return { ...state, isLoading: true };

        case UPDATE_USER_DATA_SUCCESS:
            return { ...state, isLoading: false, user: payload };

        case UPDATE_USER_DATA_ERROR:
            return { ...state, isLoading: false };

        case UPDATE_USER_PASSWORD:
            return { ...state, isLoading: true };

        case UPDATE_USER_PASSWORD_SUCCESS:
            return { ...state, isLoading: false, user: payload };

        case UPDATE_USER_PASSWORD_ERROR:
            return { ...state, isLoading: false };

        case SET_USER_SETTINGS:
            return { ...state, user: { ...state.user, userSettings: payload } };

        case SET_AFTER_AUTH_CALLBACK:
            return { ...state, afterAuthCallback: payload };

        default:
            return { ...state };
    }
};
