import React, { useCallback, useState } from "react";
import { ReactComponent as CrossIcon } from "public/icons/cross-icon.svg";
import { ReactComponent as PromoIcon } from "public/icons/promo-icon.svg";
import { isEmptyValue } from "common/utils";
import { getAppLink, getIsPromoEnabled, PROMO_SHOW_COUNTER } from "./utils";
import * as S from "./PromoView.styled";
const PromoView = () => {
  const [showPromo, setShowPromo] = useState(getIsPromoEnabled());
  const handleClosePromo = useCallback(() => {
    setShowPromo(false);
    if (isEmptyValue(localStorage.getItem("promoCloseCounter"))) {
      localStorage.setItem("promoCloseCounter", `${PROMO_SHOW_COUNTER}`);
    }
    localStorage.setItem("promoCloseCounter", (localStorage.getItem("promoCloseCounter") - 1).toString());
    sessionStorage.setItem("isPromoEnabled", "0");
  }, []);
  if (!showPromo) {
    return null;
  }
  return <S.PromoContainer>
            <S.PromoWrapper>
                <CrossIcon onClick={handleClosePromo} />
                <PromoIcon />
                <S.PromoContent>
                    <S.PromoTitle>BookScouter Mobile App</S.PromoTitle>
                    <S.PromoDescription>Simplify your selling process now!</S.PromoDescription>
                </S.PromoContent>
                <S.PromoLink href={getAppLink()} aria-label="Get App Link">
                    Get
                </S.PromoLink>
            </S.PromoWrapper>
        </S.PromoContainer>;
};
export default PromoView;