import React from "react";
import Head from "next/head";

import * as PATH from "common/constants/pathRoutes";

export const FaqSeo = () => (
    <Head>
        <title key="title">
            {"Buying & Selling Used Books - Frequently Asked Questions | BookScouter.com"}
        </title>
        <link
            rel="canonical"
            key="canonical"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.FAQ}`}
        />
        <meta
            name="description"
            key="description"
            content={
                "See the answers on popular questions about buying & selling used books, and " +
                "BookScouter website. Learn how to better use the BookScouter web app to save " +
                "on textbooks or get the most money for your old college books."
            }
        />
        <meta
            property="og:title"
            key="og:title"
            content={"Buying & Selling Used Books on BookScouter - FAQ"}
        />
        <meta
            property="og:description"
            key="og:description"
            content={
                "See the answers on popular questions about buying & selling used books, and " +
                "BookScouter website. Learn how to better use the BookScouter web app to save " +
                "on textbooks or get the most money for your old college books."
            }
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
        <meta
            property="og:url"
            key="og:url"
            content={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.FAQ}`}
        />
    </Head>
);
