import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { setIsScanning } from "contexts/App/actions";
import { useAppContext } from "contexts";
import { getSearchQueryString } from "common/utils";
import { SELL_STATE } from "common/constants/searchTypes";
import { appNotifications } from "common/constants/notifications";
import { addErrorNotification } from "common/notifications";
import MobileScannerCore from "./subcomponents/MobileScannerCore";
import BackButton from "./subcomponents/BackButton";
import PromoView from "./subcomponents/PromoView";
import { getInitialActiveTab } from "../SearchMain/utils";
import * as S from "./MobileScanner.styled";
const MobileScanner = () => {
  const scannerRef = useRef(null);
  const [constraints, setConstraints] = useState({});
  const [{
    isScanning
  }, dispatch] = useAppContext();
  const router = useRouter();
  useEffect(() => {
    window.scrollTo({
      top: 0
    });
    setConstraints({
      width: window.innerWidth * 2,
      height: window.innerHeight * 1.4
    });
  }, []);
  const onDetected = result => {
    setTimeout(() => {
      setIsScanning({
        dispatch,
        isScanning: false
      });
      const searchQueryType = router.query.type || SELL_STATE;
      const activeSearchTab = getInitialActiveTab({
        type: searchQueryType,
        location: router
      });
      window.dataLayer.push({
        searchString: result,
        event: "search",
        type: "searchScanner"
      });
      router.push(getSearchQueryString({
        searchValue: result,
        activeSearchTab
      }));
    }, 400);
  };
  const onBackClick = useCallback(() => {
    setIsScanning({
      dispatch,
      isScanning: false
    });
  }, []);
  const onError = useCallback(err => {
    const msg = err.code === 0 ? err.message : appNotifications.SCANNER_COMMON_ERROR;
    setIsScanning({
      dispatch,
      isScanning: false
    });
    addErrorNotification({
      msg
    });
  }, []);
  return <S.ScannerWrapper>
            <S.ScannerArea ref={scannerRef} isScanning={isScanning}>
                <BackButton onClick={onBackClick} />
                <S.Canvas className="drawingBuffer" width={constraints.width} height={constraints.height} />
                {isScanning ? <MobileScannerCore scannerRef={scannerRef} onDetected={onDetected} constraints={constraints} onError={onError} /> : null}
            </S.ScannerArea>
            <S.ScanZoneWrapper>
                <S.ScanZoneWrapperTop />
                <S.ScanZoneWrapperSidesContainer>
                    <S.ScanZoneWrapperLeft />
                    <S.ScanZoneWrapperRight />
                </S.ScanZoneWrapperSidesContainer>
                <S.ScanZoneWrapperBottom />
                <PromoView />
            </S.ScanZoneWrapper>
        </S.ScannerWrapper>;
};
export default MobileScanner;