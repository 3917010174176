import { createContext, useContext } from "react";

import { assertDefined } from "common/utils";

export const AuthContext = createContext(null);

export const useAuthContext = () => {
    const context = useContext(AuthContext);

    assertDefined(context);

    return context;
};
