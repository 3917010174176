import format from "date-fns/format";
import formatDistance from "date-fns/formatDistance";

import { memberArrayFormatterFromApi, metaWOPagesFormatterFromApi } from "../common";
import { bookBaseFieldsFormatterFromApi } from "../book";

export const dealItemFormatter = (item) => {
    const currency = "$";

    const book = bookBaseFieldsFormatterFromApi(item.book);

    return {
        id: item.id,
        book,
        timestamp: format(new Date(item.timestamp), "M/d 'at' hh:mm aaaaa'm'"),
        timeAgo: formatDistance(new Date(item.timestamp), new Date(), { addSuffix: true }),
        lowestOffer: `${currency}${item.lowestOffer.toFixed(2)}`,
        highestBuyback: `${currency}${item.highestBuyback.toFixed(2)}`,
        profit: `${currency}${item.profit.toFixed(2)}`,
        profitPercent: `${item.profitPercent}%`,
        sellVendorName: item.sellVendor?.name || "",
        buyVendorName: item.buyVendor.name || ""
    };
};

export const dealsFormatterFromApi = (data) => ({
    data: memberArrayFormatterFromApi(data).map(dealItemFormatter),
    ...metaWOPagesFormatterFromApi(data)
});
