import React, { useReducer } from "react";
import PropTypes from "prop-types";

import { reducer, setLazyInitState } from "./reducers";
import { BreadcrumbsContext } from "./utils";

const BreadcrumbsProvider = ({ children, breadcrumbLinks }) => {
    const breadcrumbsContext = useReducer(reducer, { breadcrumbLinks }, setLazyInitState);

    return (
        <BreadcrumbsContext.Provider value={breadcrumbsContext}>
            {children}
        </BreadcrumbsContext.Provider>
    );
};

BreadcrumbsProvider.propTypes = {
    children: PropTypes.node.isRequired,
    breadcrumbLinks: PropTypes.array
};

export default BreadcrumbsProvider;
