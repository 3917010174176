import axios from "axios";

import { errorHandler } from "../utils";

const getQueriesConfig = ({ url, options = {} }) => {
    /**
     * wp requests work w/o withCredentials flag
     * dev requests work with withCredentials flag
     */
    const clientQueryConfig = axios.create({
        baseURL: url,
        ...options
    });

    const serverQueryConfig = axios.create({
        baseURL: url,
        ...options
    });

    clientQueryConfig.interceptors.request.use(
        (config) => {
            config.headers.accept = "application/ld+json";

            // copy of bookscouter-www migration v4
            if (
                config.url !== "v4/bulk_jobs" &&
                config.url !== "v4/bulk" &&
                config.method !== "post"
            ) {
                if (config.method === "patch") {
                    config.headers["content-type"] = "application/merge-patch+json";
                }

                if (config.method !== "patch" && !config.url.startsWith("v4/auth")) {
                    config.headers["content-type"] = "application/ld+json";
                }
            }

            // disable google recaptcha
            // config.headers["mobile-auth"] = "4735148383163245";
            // }

            return { ...config };
        },
        (error) => {
            throw error;
        }
    );

    [clientQueryConfig, serverQueryConfig].forEach((axiosConfig) => {
        axiosConfig.interceptors.response.use(
            (response) => response,
            (error) => {
                const errorConfig = errorHandler(error);

                throw errorConfig;
            }
        );
    });

    return { clientQueryConfig, serverQueryConfig };
};

export default getQueriesConfig;
