import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useRouter } from "next/router";

import { useModalContext } from "contexts/Modal";
import { useAppContext } from "contexts";

import { isPresentValue } from "../utils";
import { MIX_STATE } from "../constants/searchTypes";

export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value]);

    return debouncedValue;
};

export const useDebouncedCallback = (func, delay) => {
    // Use a ref to store the timeout between renders
    // and prevent changes to it from causing re-renders
    const timeoutRef = useRef();

    return useCallback(
        (...args) => {
            const later = () => {
                clearTimeout(timeoutRef.current);
                func(...args);
            };

            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(later, delay);
        },
        [func, delay]
    );
};

export const useForwardModalParams = ({ modalType, payload = {}, deps = [] }) => {
    const { isModalOpen, setModalChildrenProps } = useModalContext();

    const forwardModalProps = useDebouncedCallback((forwardProps) => {
        setModalChildrenProps(forwardProps);

        // higher delay can cause button loading issue, for price alert it will be fixed later
    }, 200);

    useEffect(() => {
        if (isModalOpen(modalType)) {
            forwardModalProps(payload);
        }
    }, deps);
};

export const useDisableBodyInteractive = () => {
    const [{ isScanning }] = useAppContext();

    useEffect(() => {
        // hide body scroll when modal open
        document.body.classList.add("no-scroll");

        return () => {
            if (!isScanning) {
                document.body.classList.remove("no-scroll");
            }
        };
    }, []);
};

export const useOutsideAlerter = (ref, clickHandler) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */

        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                clickHandler();

                event.stopPropagation();
            }
        };

        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("click", handleClickOutside);
        };
    }, [ref, clickHandler]);
};

export const useResourceType = ({ type: initType = MIX_STATE } = {}) => {
    const router = useRouter();
    const type = useMemo(
        () => (isPresentValue(router.query.type) ? router.query.type : initType),
        [router.query.type, initType]
    );

    return { type };
};
