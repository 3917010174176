import { BUY_STATE, SELL_STATE } from "common/constants/searchTypes";

import { memberArrayFormatterFromApi, metaWOPagesFormatterFromApi } from "../common";
import { isPresentValue } from "common/utils";

export const priceHistoryFormatterFromApi = (data, withMeta = false) => ({
    data:
        memberArrayFormatterFromApi(data)
            .filter(({ bestVendor }) => isPresentValue(bestVendor))
            .map(priceHistoryItemFormatterFromApi) || [],
    ...(withMeta && {
        ...metaWOPagesFormatterFromApi(data)
    })
});

export const priceHistoryItemFormatterFromApi = (item) => ({
    dateSeen: item.dateSeen || "",
    isbn13: item.isbn13 || "",
    isbn: item.isbn || "",
    maxPrice: parseFloat(item.maxPrice).toFixed(2) || null,
    vendorName: item.bestVendor.name,
    vendorLogoPath: `/images/logos/vendors/logo-${item.bestVendor.slug}.png`
});

export const priceHistoryAmazonBuyPrice = ({
    dateSeen,
    newPrice,
    usedPrice,
    fbaPrice,
    sellersCount
}) => ({
    dateSeen,
    newPrice: newPrice.toFixed(2),
    usedPrice: usedPrice.toFixed(2),
    fbaPrice: fbaPrice.toFixed(2),
    sellersCount
});

export const priceHistoryAmazonSalesRank = ({ dateSeen, value }) => ({
    dateSeen,
    value
});

export const bestPricesHistoryFormatterFromApi = (data) => {
    const defaultValues = {
        price: null,
        date: null
    };

    return {
        [BUY_STATE]: {
            ...(isPresentValue(data.buyPrice)
                ? {
                      price: parseFloat(data.buyPrice.price).toFixed(2),
                      date: data.buyPrice.dateSeen
                  }
                : defaultValues)
        },
        [SELL_STATE]: {
            ...(isPresentValue(data.sellPrice)
                ? {
                      price: parseFloat(data.sellPrice.maxPrice).toFixed(2),
                      date: data.sellPrice.dateSeen
                  }
                : defaultValues)
        }
    };
};
