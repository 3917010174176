const getContentList = (htmlStr) => {
    const regExpContent = /<p.*?>(.*?)<\/p>/gi;

    return htmlStr.match(regExpContent);
};

export const pressDataFormatterFromApi = ({ title, content }, withFormat = false) => ({
    title: title.rendered,
    content: withFormat ? getContentList(content.rendered) : content.rendered
});
