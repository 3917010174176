import { isEmptyValue, isPresentValue, DEFAULT_BOOK_TITLE } from "common/utils";
import { BUY_STATE, RENT_STATE, SELL_STATE } from "common/constants/searchTypes";
import CONDITIONS from "common/constants/conditions";

import { BOOK_UNAVAILABLE_IMG } from "components/BookAsset/constants";

import { vendorFormatterFromApi } from "../vendors";
import { memberArrayFormatterFromApi, authorsFormatterFromApi } from "../common";
import { categoryFormatterFromApi } from "../categories";

export const couponFormatterFromApi = ({ description, id, title }) => ({
    id,
    title,
    description
});

export const bookPricesFormatterFromApi = (
    { prices, book, expected, fetched, totalVendors },
    pageType
) => {
    const vendors = prices.map((item) => {
        const vendor = {
            price: item.price,
            sellerName: item.sellerName,
            sellerNotes: item.sellerNotes,
            url: item.url,
            shipping: item.shipping,
            condition: item.condition,
            vendorType: item.type,
            rentalTerm: item.rentalTerm,
            dealEligible: item.dealEligible,
            isInstructorEdition: item.isInstructorEdition,
            isInternationalEdition: item.isInternationalEdition,
            additionalInfo: item.additionalInfo,
            ...item.vendor
        };

        return bookVendorFormatterFromApi(vendor, book);
    });

    return {
        book: bookFormatterFromApi(book),
        sell: {
            data: vendors
                .filter((vendor) => !vendor.vendorType)
                .map((vendor) => ({ ...vendor, shimmered: parseFloat(vendor.price) < 0 })),
            meta: pageType === SELL_STATE ? { expected, fetched, totalVendors } : {}
        },
        buy: {
            data: vendors.filter(
                (vendor) => vendor.vendorType === "Buy" && parseFloat(vendor.price) > 0
            ),
            meta: pageType === BUY_STATE ? { expected, fetched } : {}
        },
        rent: {
            data: vendors
                .filter((vendor) => vendor.vendorType === "Rent" && parseFloat(vendor.price) > 0)
                .map((vendor) => ({
                    ...vendor,
                    displayCondition:
                        vendor.condition.toLowerCase() === CONDITIONS.EBOOK ? "Digital" : null
                })),
            meta: pageType === RENT_STATE ? { expected, fetched } : {}
        }
    };
};

export const bookVendorFormatterFromApi = (vendor, book) => ({
    ...vendorFormatterFromApi(vendor),
    price: parseFloat(vendor.price).toFixed(2),
    sellerName: vendor.sellerName,
    sellerNotes: vendor.sellerNotes,
    minimumOrder: parseFloat(vendor.minimumOrder).toFixed(2),
    coupons: vendor.coupons.map(couponFormatterFromApi),
    sellLink:
        vendor?.id && book?.isbn13
            ? `${process.env.NEXT_PUBLIC_API_URL}exits/sell/${vendor.id}/${book.isbn13}`
            : null,
    buyRentLink:
        vendor?.id && book?.isbn13
            ? // eslint-disable-next-line max-len
              `${process.env.NEXT_PUBLIC_API_URL}exits/buy/${vendor.id}/${book.isbn13}?url=${vendor.url}`
            : null,
    url: vendor.url,
    imgPath: `/images/logos/vendors/icon-${vendor.slug.split("-")[0]}`,
    featuredInBulkComparison: vendor?.bulkInfo?.inBulkComparison,
    allowBulk: vendor?.bulkInfo?.allowBulk,
    shipping: !isEmptyValue(vendor.shipping) ? vendor.shipping : null,
    condition: !isEmptyValue(vendor.condition) ? vendor.condition : "",
    vendorType: !isEmptyValue(vendor.vendorType) ? vendor.vendorType : null,
    rentalTerm: !isEmptyValue(vendor.rentalTerm) ? parseFloat(vendor.rentalTerm) : null,
    dealEligible: !isEmptyValue(vendor.dealEligible) ? vendor.dealEligible : null,
    priceWithShipping: !isEmptyValue(vendor.shipping)
        ? (parseFloat(vendor.price) + parseFloat(vendor.shipping)).toFixed(2)
        : parseFloat(vendor.price).toFixed(2),
    subVendors: [],
    currency: "$",
    isInternationalEdition: vendor.isInternationalEdition,
    isInstructorEdition: vendor.isInstructorEdition,
    additionalInfo: vendor.additionalInfo
});

export const bookBaseFieldsFormatterFromApi = (data) => ({
    isbn10: data.isbn10 || null,
    isbn13: data.isbn13,
    title: data.title ? data.title : DEFAULT_BOOK_TITLE,
    author: authorsFormatterFromApi(data.author),
    img: data.image ? data.image.replace("_SL75_", "_SL300_") : BOOK_UNAVAILABLE_IMG,
    previewImg: data.image ? data.image.replace("_SL75_", "_SL500_") : BOOK_UNAVAILABLE_IMG,
    edition: data.edition || null,
    slug: data.slug,
    binding: data.binding || null,
    get isbn() {
        return this.isbn13 || this.isbn10;
    },
    get modalTitle() {
        return isEmptyValue(data.title) ? this.isbn : this.title;
    },
    get slugForUrl() {
        return encodeURIComponent(
            `${this.isbn}${isPresentValue(this.slug) ? `-${this.slug}` : ""}`
        );
    },
    get referenceAuthors() {
        return isPresentValue(data.authors) ? data.authors.map(categoryFormatterFromApi) : null;
    }
});

export const bookFormatterFromApi = (data) => ({
    ...bookBaseFieldsFormatterFromApi(data),
    publisher: data.publisher,
    released: isPresentValue(data.publishedDate) ? data.publishedDate : null,
    amazonLink:
        data.asin || data.isbn10
            ? `${process.env.NEXT_PUBLIC_AMAZON_URL}/dp/${
                  data.asin ? data.asin : data.isbn10
              }/?ie=UTF8&tag=bookscouting-20&linkCode=ogi&th=1&psc=1`
            : null,
    amazonSalesRank: data.amazonSalesRank,
    amazonCount: data.amazonCount,
    amazonLowestPrice: data.amazonLowestPrice,
    amazonCurrency: "$",
    format: `${data.binding && data.binding}${
        data.numberOfPages && `, ${data.numberOfPages} pages`
    }`,
    pagesCount: isPresentValue(data.numberOfPages) ? parseInt(data.numberOfPages, 10) : null,
    aggregateRating: {
        bestRating: data?.aggregateRating.bestRating,
        ratingCount: data?.aggregateRating.ratingCount,
        ratingValue: data?.aggregateRating.ratingValue
    },
    description: data.description,
    bindings: data.otherBindings.map(bookBaseFieldsFormatterFromApi),
    creationInProcess: data.creationInProcess || null,
    get hasCategories() {
        return data.categories.length ? true : false;
    },
    get categories() {
        return this.hasCategories ? data.categories.map(categoryFormatterFromApi) : [];
    },
    get parentCategory() {
        return this.hasCategories ? this.categories[0].parent : null;
    },
    get referencePublisher() {
        return isPresentValue(data.publisherObj)
            ? categoryFormatterFromApi(data.publisherObj)
            : null;
    },
    get referenceCategories() {
        if (!this.hasCategories) {
            return [];
        }

        if (isPresentValue(this.parentCategory)) {
            return [
                { ...this.parentCategory, isActive: true },
                { ...this.categories[0], isActive: true }
            ];
        }

        return [{ ...this.categories[0], isActive: true }];
    }
});

export const bookSalesFormatterFromApi = (data) =>
    memberArrayFormatterFromApi(data).map(bookSalesItemFormatterFromApi);

export const bookEditionsFormatterFromApi = (data) => {
    return data["hydra:member"].map(bookBaseFieldsFormatterFromApi);
};

const bookSalesItemFormatterFromApi = (data) => {
    return {
        dateSeen: data.dateSeen,
        isbn: data.isbn,
        value: data.value
    };
};

const bookSellersItemFormatterFromApi = (data) => {
    return {
        dateSeen: data.dateSeen,
        isbn: data.isbn,
        value: data.sellersCount
    };
};

export const bookSellersFormatterFromApi = (data) =>
    memberArrayFormatterFromApi(data).map(bookSellersItemFormatterFromApi);
