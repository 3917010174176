import React from "react";
import Link from "next/link";
import * as PATH from "common/constants/pathRoutes";
import { FACEBOOK_URL, TWITTER_URL, YOUTUBE_URL, TIKTOK_URL, INSTAGRAM_URL, PINTEREST_URL } from "common/constants/socialLinks";
import { ReactComponent as FacebookIcon } from "public/icons/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "public/icons/twitter-icon.svg";
import { ReactComponent as PinterestIcon } from "public/icons/pinterest-icon.svg";
import { ReactComponent as InstagramIcon } from "public/icons/instagram-icon.svg";
import { ReactComponent as TiktokIcon } from "public/icons/tiktok-icon.svg";
import { ReactComponent as YoutubeIcon } from "public/icons/youtube-icon.svg";
import { ReactComponent as GooglePlayIcon } from "public/icons/google-play.svg";
import { ReactComponent as AppStoreIcon } from "public/icons/app-store.svg";
import Dropdown from "../subcomponents/Dropdown";
import { CATEGORIES_SECTION, ABOUT_SECTION, MORE_SECTION } from "../constants/sections";
import * as S from "../Footer.styled";
const isCurrentPathIncludeLink = (path, link) => path === link.path;
const buildCategoriesLinks = () => [{
  label: "Books by Category",
  path: PATH.CATEGORIES,
  id: "Books by Category footer link"
}, {
  label: "Books by Author",
  path: PATH.AUTHORS,
  id: "Books by Author footer link"
}, {
  label: "Books by Publisher",
  path: PATH.PUBLISHERS,
  id: "Books by Publisher footer link"
}];
const buildAboutLinks = ({
  onContactClick
}) => [{
  label: "Vendors",
  path: PATH.VENDORS,
  id: "Vendors footer link"
}, {
  label: "FAQ",
  path: PATH.FAQ,
  id: "Faq footer link"
}, {
  label: "Press",
  path: PATH.PRESS,
  id: "Press footer link"
}, {
  label: "Reviews",
  path: PATH.REVIEWS,
  id: "Reviews footer link"
}, {
  label: "Contact",
  path: PATH.CONTACT,
  id: "Contact footer link",
  onClick: onContactClick
}];
export const buildMoreLinks = () => [{
  label: "Affiliate Program",
  path: PATH.AFFILIATES,
  id: "Affiliate Program footer link"
}, {
  label: "Textbook Scholarship",
  path: PATH.SCHOLARSHIP,
  id: "Textbook Scholarship footer link"
}, {
  label: "College Bookstores",
  path: PATH.COLLEGE_BOOKSTORES,
  id: "College Bookstores footer link"
}, {
  label: "Blog",
  url: PATH.BLOG,
  id: "Blog footer link"
}, {
  label: "Tips",
  url: PATH.TIPS,
  id: "Tips footer link"
}];
export const buildSocialLinks = () => [{
  label: "Facebook",
  dataTestId: "facebook",
  url: FACEBOOK_URL,
  icon: FacebookIcon
}, {
  label: "Twitter",
  dataTestId: "twitter",
  url: TWITTER_URL,
  icon: TwitterIcon
}, {
  label: "Tiktok",
  dataTestId: "tiktok",
  url: TIKTOK_URL,
  icon: TiktokIcon
}, {
  label: "Instagram",
  dataTestId: "instagram",
  url: INSTAGRAM_URL,
  icon: InstagramIcon
}, {
  label: "Youtube",
  dataTestId: "youtube",
  url: YOUTUBE_URL,
  icon: YoutubeIcon
}, {
  label: "Pinterest",
  dataTestId: "pinterest",
  url: PINTEREST_URL,
  icon: PinterestIcon
}];
const buildSubLinks = () => [{
  path: PATH.TERMS,
  label: "Terms"
}, {
  path: PATH.PRIVACY,
  label: "Privacy"
}];
export const buildAppLinks = () => [{
  label: "Google Play",
  dataTestId: "google-play-button",
  url: "https://play.google.com/store/apps/details?id=com.BookScouter",
  icon: GooglePlayIcon,
  iconProps: {
    "aria-hidden": true
  }
}, {
  label: "App Store",
  dataTestId: "app-store-button",
  url: "https://apps.apple.com/us/app/bookscouter-mobile/id366508853",
  icon: AppStoreIcon,
  iconProps: {
    "aria-hidden": true
  }
}];
export const buildSections = ({
  handleContactModal,
  path
}) => {
  const categoriesLinks = buildCategoriesLinks();
  const aboutLinks = buildAboutLinks({
    onContactClick: handleContactModal
  });
  const moreLinks = buildMoreLinks();
  return [{
    title: ABOUT_SECTION,
    opened: true,
    links: aboutLinks
  }, {
    title: CATEGORIES_SECTION,
    opened: Boolean(categoriesLinks.filter(link => isCurrentPathIncludeLink(path, link)).length),
    links: categoriesLinks
  }, {
    title: MORE_SECTION,
    opened: Boolean(moreLinks.filter(link => isCurrentPathIncludeLink(path, link)).length),
    links: moreLinks
  }];
};
export const renderSections = sections => sections.map(({
  links,
  ...restProps
}, index) => <Dropdown key={index} {...restProps}>
            {renderLinks(links)}
        </Dropdown>);
const renderLinks = links => links.map(({
  label,
  path,
  url,
  id,
  ...linkProps
}, index) => <React.Fragment key={index}>
            <S.FooterLinkItem data-test-id="Footer-FooterLinkItem">
                {path ? <Link href={path} prefetch={false} passHref>
                        <S.MainLink data-test-id={id} {...linkProps}>
                            {label}
                        </S.MainLink>
                    </Link> : <S.MainLink href={url} data-test-id={id} {...linkProps}>
                        {label}
                    </S.MainLink>}
            </S.FooterLinkItem>
        </React.Fragment>);
export const renderSocialLinks = links => links.map(({
  label,
  url,
  icon: IconComponent,
  dataTestId
}, index) => {
  const newDataTestId = dataTestId ? `footer-${dataTestId}` : "Footer-FooterLinkItem";
  return <S.FooterLinkItem key={index} data-test-id={newDataTestId}>
                <S.Link href={url} aria-label={label} target="_blank" rel="nofollow noopener noreferrer">
                    <IconComponent />
                </S.Link>
            </S.FooterLinkItem>;
});
export const renderSubLinks = () => buildSubLinks().map(({
  label,
  path
}, index) => <S.FooterLinkItem key={index} data-test-id="Footer-FooterLinkItem">
            <Link href={path} prefetch={false} passHref>
                <S.SubLink>{label}</S.SubLink>
            </Link>
        </S.FooterLinkItem>);