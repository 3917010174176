export const CUSTOM_LOGO_VENDORS = {
    betterworldbooks: true,
    bluerocketbooks: true,
    booksintocash: true,
    "campus-book-rentals": true,
    sellbackyourbook: true,
    textbookcashback: true,
    valore: true,
    "buy-valor": true,
    textbookrush: true
};
