import React, { memo } from "react";
import PropTypes from "prop-types";
import { PRIMARY, ACCENT, ACCENT_TEXT, FILTER, NEUTRAL, UPLOAD, PRIMARY_TEXT, PRIMARY_TEXT_CUSTOM, PRIMARY_TEXT_BORDERED, OUTLINE_SECONDARY, OUTLINE_PRIMARY } from "common/constants/buttonTypes";
import Spinner from "../Spinner";
import { spinnerColorsConfig } from "./utils";
import * as S from "./Button.styled";

/**
 * Button UI component for user interaction
 */

const Button = ({
  variant,
  label,
  onClick,
  isActive,
  styles,
  disabled,
  icon: IconComponent,
  iconStyles,
  iconPosition,
  isLoading,
  loadingText,
  loadingProps,
  ...restProps
}) => {
  const buttonTypes = {
    [PRIMARY]: S.ButtonPrimary,
    [ACCENT]: S.ButtonAccent,
    [ACCENT_TEXT]: S.ButtonAccentText,
    [FILTER]: S.ButtonFilter,
    [NEUTRAL]: S.ButtonNeutral,
    [UPLOAD]: S.ButtonUpload,
    [PRIMARY_TEXT]: S.ButtonPrimaryText,
    [PRIMARY_TEXT_CUSTOM]: S.ButtonPrimaryTextCustom,
    [PRIMARY_TEXT_BORDERED]: S.ButtonPrimaryTextBordered,
    [OUTLINE_SECONDARY]: S.ButtonOutlineSecondary,
    [OUTLINE_PRIMARY]: S.ButtonOutlinePrimary
  };
  const activeClassName = isActive && "active";
  const ButtonComponent = buttonTypes[variant];
  return <ButtonComponent type="button" name={label} aria-label={label} className={activeClassName} disabled={isLoading || disabled} onClick={onClick} style={{
    ...styles
  }} isLoading={isLoading} iconPosition={iconPosition} IconComponent={IconComponent} {...restProps}>
            {isLoading ? <S.LoadingWrapper>
                    <Spinner {...spinnerColorsConfig[variant]} {...loadingProps} />
                    {loadingText && loadingText}
                </S.LoadingWrapper> : <>
                    {IconComponent && iconPosition === "left" && <IconComponent style={iconStyles} />}
                    {label}
                    {IconComponent && iconPosition === "right" && <IconComponent style={iconStyles} />}
                </>}
        </ButtonComponent>;
};
Button.propTypes = {
  /**
   * Button variant
   */
  variant: PropTypes.oneOf([PRIMARY, PRIMARY_TEXT, ACCENT, ACCENT_TEXT, FILTER, NEUTRAL, UPLOAD, PRIMARY_TEXT_CUSTOM, PRIMARY_TEXT_BORDERED, OUTLINE_SECONDARY, OUTLINE_PRIMARY]),
  /**
   * Button contents
   */
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]),
  /**
   * Button click handler
   */
  onClick: PropTypes.func,
  /**
   * Button styles to redefine default styles
   */
  styles: PropTypes.object,
  /**
   * Add active className to Button
   */
  isActive: PropTypes.bool,
  /**
   * Disable Button
   */
  disabled: PropTypes.bool,
  /**
   * Render Button with Icon
   */
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /**
   * Icon styles in button
   */
  iconStyles: PropTypes.object,
  iconPosition: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  loadingProps: PropTypes.object
};
Button.defaultProps = {
  variant: PRIMARY,
  onClick: () => {},
  isActive: false,
  disabled: false,
  label: "",
  icon: null,
  styles: {},
  iconStyles: {},
  iconPosition: "left",
  isLoading: false,
  loadingText: null,
  loadingProps: {}
};
export default memo(Button);