import React, { useReducer } from "react";
import PropTypes from "prop-types";

import { reducer, initState } from "./reducers";
import { AppContext } from "./utils";

const AppProvider = ({ children }) => {
    const appContext = useReducer(reducer, initState);

    return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default AppProvider;
