import React, { useReducer } from "react";
import PropTypes from "prop-types";

import { reducer, initState } from "./reducers";
import { AuthContext } from "./utils";

const AuthProvider = ({ children }) => {
    const authContext = useReducer(reducer, initState);

    return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default AuthProvider;
