import React from "react";
import Head from "next/head";
import PropTypes from "prop-types";

import * as PATH from "common/constants/pathRoutes";

export const VendorDetailsSeo = ({ name, id, slug, logoPath }) => (
    <Head>
        <title key="title">
            {`${name} Reviews, Address, Shipping & Payment Methods | BookScouter.com`}
        </title>
        <link
            rel="canonical"
            key="canonical"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.VENDORS}/${id}-${slug}`}
        />
        <meta
            name="description"
            key="description"
            content={
                `Check ${name} reviews posted by real customers about their experience ` +
                "with that website. " +
                "Customer feedback and company information all in one place. Learn more about " +
                `${name} before you sell your books to them.`
            }
        />
        <meta property="og:title" key="og:title" content={`${name} Reviews on BookScouter`} />
        <meta
            property="og:description"
            key="og:description"
            content={
                `Check ${name} reviews posted by real customers about their experience with ` +
                "that website. " +
                "Customer feedback and company information all in one place. Learn more about " +
                `${name} before you sell your books to them.`
            }
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:image" key="og:image" content={`${logoPath}.png`} />
        <meta
            property="og:url"
            key="og:url"
            content={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.VENDORS}/${id}-${slug}`}
        />
    </Head>
);

VendorDetailsSeo.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    logoPath: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
};
