import React from "react";
import Head from "next/head";

import * as PATH from "common/constants/pathRoutes";

import { baseOrgSchema } from "seo";

export const MainOrgScheme = () => (
    // specially separated from main meta tags, it can cause duplicated seo
    <Head>
        <script
            key="organization_schema"
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: JSON.stringify(baseOrgSchema)
            }}
        />
    </Head>
);

export const MainSeo = () => (
    <Head>
        <title key="title">
            {"Sell Textbooks and Used Books - Buyback Comparison | BookScouter.com"}
        </title>
        <link rel="canonical" key="canonical" href={process.env.NEXT_PUBLIC_BASE_URL} />
        <meta
            name="facebook-domain-verification"
            key="facebook-domain-verification"
            content="yxtrtk7mrmfccr5l0b0cc81ncgz324"
        />
        <meta
            name="description"
            key="description"
            content={
                "Sell your old textbooks and used books online at " +
                "the best price with BookScouter. We compare prices from 30+ vendors. " +
                "Single & multi ISBN lookup. Try now!"
            }
        />
        <meta
            property="og:title"
            key="og:title"
            content="Buy & Sell Textbooks and Used Books - Price Comparison"
        />
        <meta
            property="og:description"
            key="og:description"
            content={
                "Sell your old textbooks and used books online at " +
                "the best price with BookScouter. We compare prices from 30+ vendors. " +
                "Single & multi ISBN lookup. Try now!"
            }
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
        <meta property="og:url" key="og:url" content={process.env.NEXT_PUBLIC_BASE_URL} />
    </Head>
);

export const MainBuySeo = () => (
    <Head>
        <title key="title">
            {"Buy Cheap Textbooks Online - Used College Books Search Engine | BookScouter.com"}
        </title>
        <link
            rel="canonical"
            key="canonical"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.BUY}`}
        />
        <meta
            name="description"
            key="description"
            content={
                "Buy textbooks and used books for less with BookScouter. We will find cheap " +
                "books for you checking 25+ online booksellers. Search for your " +
                "college books by ISBN now!"
            }
        />
        <meta
            property="og:title"
            key="og:title"
            content="Buy Cheap Textbooks & Used Books - Price Comparison"
        />
        <meta
            property="og:description"
            key="og:description"
            content={
                "Buy textbooks and used books for less with BookScouter. We will find cheap " +
                "books for you checking 25+ online booksellers. Search for your " +
                "college books by ISBN now!"
            }
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
        <meta
            property="og:url"
            key="og:url"
            content={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.BUY}`}
        />
    </Head>
);

export const MainRentSeo = () => (
    <Head>
        <title key="title">
            {"Rent Books Online - Textbook Rental Comparison | BookScouter.com"}
        </title>
        <link
            rel="canonical"
            key="canonical"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.RENT}`}
        />
        <meta
            name="description"
            key="description"
            content={
                "Find the best textbook rental price with BookScouter comparison engine. " +
                "Check 20+ vendors with a single ISBN search, rent books online & save money."
            }
        />
        <meta
            property="og:title"
            key="og:title"
            content="Rent Books Online - Rental Price Comparison"
        />
        <meta
            property="og:description"
            key="og:description"
            content={
                "Find the best textbook rental price with BookScouter comparison engine. " +
                "Check 20+ vendors with a single ISBN search, rent books online & save money."
            }
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
        <meta
            property="og:url"
            key="og:url"
            content={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.RENT}`}
        />
    </Head>
);

export const ScholarshipSeo = () => (
    <Head>
        <title key="title">{"BookScouter Quarterly Book Scholarship | BookScouter.com"}</title>
        <link
            rel="canonical"
            key="canonical"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.SCHOLARSHIP}`}
        />
        <meta
            name="description"
            key="description"
            content={
                "BookScouter is sponsoring a textbook scholarship since 2018. Each quarter " +
                "the company awards a student with a $500 scholarship to be used towards " +
                "purchasing their textbooks. Feel free to apply!"
            }
        />
    </Head>
);
