import React, { useEffect, useCallback, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/nextjs";
import { MAIN } from "common/constants/pathRoutes";
import { IMAGE_BLUR_PLACEHOLDER, isPresentValue } from "common/utils";
import { PageMainContainer } from "common/styles";
import { ACCENT_TEXT } from "common/constants/buttonTypes";
import ModalTypes from "common/constants/modalTypes";
import { useModalContext } from "contexts/Modal";
import Button from "components/Button";
import { useErrorResourceContext } from "contexts/ErrorResource/utils";
import ServerErrorImg from "public/images/error/server-error.svg";
import * as S from "./ServerError.styled";
import { ERROR_CUSTOM_TEXT } from "./constants";
const ServerError = ({
  statusCode,
  statusText,
  showLink
}) => {
  const {
    cleanUpResourceError,
    resourceError
  } = useErrorResourceContext();
  const text = ERROR_CUSTOM_TEXT[statusCode] || statusText;
  const router = useRouter();
  const pathname = router.asPath;
  const [prevPathname, setPrevPathname] = useState(pathname);
  const {
    modalAction
  } = useModalContext();
  const handleContactModal = useCallback(() => {
    modalAction({
      type: ModalTypes.CONTACT,
      title: "Contact us"
    });
  }, []);
  useEffect(() => {
    if (prevPathname !== pathname) {
      cleanUpResourceError();
    }
    setPrevPathname(pathname);
  }, [prevPathname, pathname]);
  useEffect(() => {
    if (isPresentValue(resourceError)) {
      Sentry.captureException(resourceError);
      console.error(resourceError);
    }
  }, [resourceError]);
  return <>
            <PageMainContainer>
                <S.ContentWrapper>
                    <S.Title>{statusCode}</S.Title>
                    <S.SubTitle>{text}</S.SubTitle>
                    <S.ImageContent>
                        <Image src={ServerErrorImg} alt={`server error ${statusCode} image`} aria-label={`server error ${statusCode} image`} priority blurDataURL={IMAGE_BLUR_PLACEHOLDER} placeholder="blur" layout="fill" objectFit="contain" />
                    </S.ImageContent>
                    {showLink ? <Link href={MAIN} passHref>
                            <S.Link>Back to homepage</S.Link>
                        </Link> : <S.Description>
                            <S.DescriptionTitle>Oops, something went wrong</S.DescriptionTitle>
                            {"Try to refresh this page or feel free to "}
                            <Button variant={ACCENT_TEXT} label={"contact us"} onClick={handleContactModal} />
                            {" if the problem persists"}
                        </S.Description>}
                </S.ContentWrapper>
            </PageMainContainer>
        </>;
};
ServerError.propTypes = {
  statusCode: PropTypes.number.isRequired,
  statusText: PropTypes.string.isRequired,
  showLink: PropTypes.bool.isRequired
};
ServerError.defaultProps = {
  statusCode: 404,
  statusText: "Oops! Page not found",
  showLink: true
};
export default ServerError;