import React from "react";
import PropTypes from "prop-types";
import * as S from "./NoticePopup.styled";
const NoticePopup = ({
  onClose,
  children
}) => {
  return <S.NoticePopupWrapper>
            <S.CloseButton onClick={onClose}>
                <S.CrossIcon />
            </S.CloseButton>
            {children}
        </S.NoticePopupWrapper>;
};
NoticePopup.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired
};
export default NoticePopup;