import { MAIN, BUY, RENT } from "common/constants/pathRoutes";
import { SELL_STATE, BUY_STATE, RENT_STATE } from "common/constants/searchTypes";

export const searchTabsConfig = [
    {
        label: "Sell",
        path: MAIN,
        type: SELL_STATE,
        id: "Sell tab"
    },
    {
        label: "Buy",
        path: BUY,
        type: BUY_STATE,
        id: "Buy tab"
    },
    {
        label: "Rent",
        path: RENT,
        type: RENT_STATE,
        id: "Rent tab"
    }
];

export const getInitialActiveTab = ({ type, location }) => {
    if (type === BUY_STATE || location.asPath === BUY) {
        return BUY_STATE;
    } else if (type === RENT_STATE || location.asPath === RENT) {
        return RENT_STATE;
    } else {
        return SELL_STATE;
    }
};
