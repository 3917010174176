import React from "react";
import Head from "next/head";

import * as PATH from "common/constants/pathRoutes";

export const WidgetSeo = () => (
    <Head>
        <title key="title">
            {"BookScouter Search Box Widget for Affiliates | BookScouter.com"}
        </title>
        <link
            rel="canonical"
            key="canonical"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.WIDGET}`}
        />
        <meta
            name="description"
            key="description"
            content={
                "Add BookScouter search box widget to your website to let your visitors compare " +
                "book prices instantly with a single search. Join BookScouter affiliate program " +
                "to make money by referring visitors to BookScouter.com. Apply today!"
            }
        />
        <meta property="og:title" key="og:title" content={"BookScouter Search Box Widget"} />
        <meta
            property="og:description"
            key="og:description"
            content={
                "Add BookScouter search box widget to your website to let your visitors compare " +
                "book prices instantly with a single search. Join BookScouter affiliate program " +
                "to make money by referring visitors to BookScouter.com. Apply today!"
            }
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
        <meta
            property="og:url"
            key="og:url"
            content={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.WIDGET}`}
        />
    </Head>
);
