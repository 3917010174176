import React from "react";
import Head from "next/head";

import * as PATH from "common/constants/pathRoutes";

export const AffiliateSeo = () => (
    <Head>
        <title key="title">
            {"Bookscouter Affiliate Program - Book Business Referral Program | BookScouter.com"}
        </title>
        <link
            rel="canonical"
            key="canonical"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.AFFILIATES}`}
        />
        <meta
            name="description"
            key="description"
            content={
                "Join BookScouter Affiliate Program to get commissions by referring visitors " +
                "to BookScouter.com. Add a search box widget on your site or share a referral " +
                "link to your followers. Register as a BookScouter partner today!"
            }
        />
        <meta property="og:title" key="og:title" content={"Bookscouter Affiliate Program"} />
        <meta
            property="og:description"
            key="og:description"
            content={
                "Join BookScouter Affiliate Program to get commissions by referring visitors " +
                "to BookScouter.com. Add a search box widget on your site or share a referral " +
                "link to your followers. Register as a BookScouter partner today!"
            }
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
        <meta
            property="og:url"
            key="og:url"
            content={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.AFFILIATES}`}
        />
    </Head>
);
