export const appNotifications = {
    LOGIN_SUCCESS_NOTIFICATION: "You've successfully logged in.",
    RESET_PASS_SUCCESS_NOTIFICATION: "We've sent you a password reset email.",
    LOGOUT_SUCCESS_NOTIFICATION: "You've successfully logged out.",
    UPDATE_USER_DATA_SUCCESS_NOTIFICATION: "Your user settings have been updated.",
    UPDATE_USER_PASSWORD_SUCCESS_NOTIFICATION: "Your password has been updated.",
    SEND_COMMENT_SUCCESS_NOTIFICATION: "Your comment has been sent.",
    SEND_PRICE_REPORT_SUCCESS_NOTIFICATION: "Your pricing issue report has been sent.",
    STRIPE_API_ERROR_NOTIFICATION:
        "Something went wrong with Stripe Api. Please try again in a few minutes.",
    ADD_WATCHLIST_SUCCESS_NOTIFICATION: "The book is successfully added to your watchlist.",
    ADD_WATCHLIST_SELL_SUCCESS_NOTIFICATION:
        "The book is successfully added to your Sell watchlist.",
    ADD_WATCHLIST_BUY_SUCCESS_NOTIFICATION: "The book is successfully added to your Buy watchlist.",
    WATCHLIST_LIMIT_ERROR_NOTIFICATION: "Sorry, you've reached your watchlist limit. ",
    WATCHLIST_REMOVE_SUCCESS_NOTIFICATION: "The book is successfully removed from your watchlist.",
    WATCHLIST_BOOK_SUBSCRIBE_SUCCESS_NOTIFICATION: "Notification has been successfully updated.",
    WATCHLIST_BOOK_PRICE_UPDATE_SUCCESS_NOTIFICATION: "Price has been successfully updated.",
    WATCHLIST_BOOK_PRICE_UPDATE_ERROR_NOTIFICATION:
        "Unsuccessful price update. Please try again in a few minutes.",
    SUBSCRIBE_USER_SUCCESS_NOTIFICATION: "You've successfully subscribed to Pro Tools!",
    SEND_VENDOR_FEEDBACK_SUCCESS_NOTIFICATION:
        "Thank you for your feedback! We'll review and publish it soon.",
    CHANGE_CARD_SUCCESS: "You've successfully changed a payment card.",
    UPDATE_VENDORS_SETTINGS_SUCCESS: "You've successfully updated vendor settings.",
    SEND_DEAL_NOTIFICATION_SUCCESS: "Your deal notification settings have been saved.",
    UPDATE_DEAL_VENDORS_FILTER_NOTIFICATION_SUCCESS: "Your deal vendor settings have been saved.",
    SEND_SELLER_REPORT_NOTIFICATION_SUCCESS: "The report has been successfully sent.",
    DEALS_DETAILS_NOTIFICATION_ERROR:
        "We couldn't get the deal details. Please check the table for the most recent deals.",
    WARN_SELECT_VENDOR: "Please select at least one vendor.",
    VALIDATION_NUMBER_VALUE: "Please enter a number.",
    VALIDATION_PRICE_THRESHOLD_SELL:
        "Please enter the price higher than the current best price or higher than $0.01.",
    VALIDATION_PRICE_THRESHOLD_BUY:
        "Please enter the price lower than the current best price or higher than $0.01.",
    VALIDATION_PRICE_WATCHLIST_SELL: "Enter more than the current price.",
    VALIDATION_PRICE_WATCHLIST_BUY: "Enter less than the current price.",
    VALIDATION_WATCHLIST_NUMBER_VALUE: "Please enter a number not lower than 0.01.",
    UPDATE_PRICE_ALERT_SUCCESS: "Price alert has been updated.",
    SCANNER_COMMON_ERROR: "Scanner initialization error",
    GRID_ISBN_VALIDATION_ERROR: (isbn) =>
        `The ISBN ${isbn} is not valid. Please enter a valid ISBN.`,
    CANCEL_SUBSCRIPTION_NOTIFICATION_SUCCESS: (period) =>
        `Your subscription has been cancelled, effective ${period}.`
};
