import { isIOS } from "react-device-detect";

const LINKS = {
    IOS: "https://apps.apple.com/us/app/bookscouter-mobile/id366508853",
    ANDROID: "https://play.google.com/store/apps/details?id=com.BookScouter"
};

export const PROMO_SHOW_COUNTER = 3;

export const getAppLink = () => {
    if (isIOS) {
        return LINKS.IOS;
    }

    return LINKS.ANDROID;
};

export const getIsPromoEnabled = () => {
    const promoCloseCounter = localStorage.getItem("promoCloseCounter");
    const isPromoEnabled = sessionStorage.getItem("isPromoEnabled");

    if (
        (promoCloseCounter === null ||
            (Number(promoCloseCounter) > 0 && Number(promoCloseCounter) <= PROMO_SHOW_COUNTER)) &&
        isPromoEnabled === null
    ) {
        return true;
    }

    if (Number(promoCloseCounter) === 0) {
        return false;
    }

    return Boolean(Number(isPromoEnabled));
};
