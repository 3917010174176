import React from "react";
import Head from "next/head";

import * as PATH from "common/constants/pathRoutes";

export const ScholarshipSeo = () => (
    <Head>
        <title key="title">
            {"Quarterly Book Scholarship - BookScouter Scholarship for Textbooks | BookScouter.com"}
        </title>
        <link
            rel="canonical"
            key="canonical"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.SCHOLARSHIP}`}
        />
        <meta
            name="description"
            key="description"
            content={
                "BookScouter has been sponsoring a textbook scholarship since 2018. Each quarter " +
                "the company awards a student with a $500 scholarship to purchase their college " +
                "books. Feel free to apply!"
            }
        />
        <meta
            property="og:title"
            key="og:title"
            content={
                "Quarterly Book Scholarship - BookScouter Scholarship for Textbooks | " +
                "BookScouter.com"
            }
        />
        <meta
            property="og:description"
            key="og:description"
            content={
                "BookScouter has been sponsoring a textbook scholarship since 2018. Each quarter " +
                "the company awards a student with a $500 scholarship to purchase their college " +
                "books. Feel free to apply!"
            }
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
        <meta
            property="og:url"
            key="og:url"
            content={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.SCHOLARSHIP}`}
        />
    </Head>
);
