import React from "react";
import * as S from "./Loader.styled";
const Loader = () => <S.LoaderStyledComponent data-test-id="Loader">
        <svg version="1.1" id="animated-logo" className="animated-logo" x="0px" y="0px" viewBox="-10 -10 145 128.2">
            <g>
                <rect className="book" width="7" height="108.2" />
                <rect className="book" x="10" y="11.1" width="7.3" height="97.1" />
                <rect className="book" x="21.2" y="5.8" width="13" height="102.4" />
                <rect className="book" x="37.8" width="7.9" height="108.2" />
                <rect className="book" x="49" y="15.8" width="13" height="92.4" />
                <rect className="book" x="65.1" y="9.1" width="9.7" height="99.1" />
                <rect className="book" x="83.2" y="1.3" transform="matrix(0.9933 -0.1152 0.1152 0.9933 -5.6925 10.6636)" width="12.4" height="106.5" />
                <rect className="book" x="104.1" y="10.5" width="11.2" height="97.8" />
                <rect className="book" x="119.6" width="5.4" height="108.2" />
            </g>
        </svg>
    </S.LoaderStyledComponent>;
export default Loader;