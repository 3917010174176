import React from "react";
import Head from "next/head";

import * as PATH from "common/constants/pathRoutes";

export const PressSeo = () => (
    <Head>
        <title key="title">
            {"BookScouter mentioned on Forbes, LifeHacker, NY Times, Inc. | BookScouter.com"}
        </title>
        <link
            rel="canonical"
            key="canonical"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.PRESS}`}
        />
        <meta
            name="description"
            key="description"
            content={
                "Is BookScouter legit? Since its launch in August 2007, BookScouter has been " +
                "featured on national television and syndicated radio shows, and in many " +
                "newspapers and magazines. Learn more about the brand and its reputation."
            }
        />
        <meta property="og:title" key="og:title" content={"BookScouter.com in the News"} />
        <meta
            property="og:description"
            key="og:description"
            content={
                "Is BookScouter legit? Since its launch in August 2007, BookScouter has been " +
                "featured on national television and syndicated radio shows, and in many " +
                "newspapers and magazines. Learn more about the brand and its reputation."
            }
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
        <meta
            property="og:url"
            key="og:url"
            content={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.PRESS}`}
        />
    </Head>
);
