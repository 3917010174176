import React from "react";
import Cookies from "js-cookie";
import moment from "moment";
import ReactHtmlParser, { processNodes } from "react-html-parser";

import Loader from "components/Loader";
import Button from "components/Button";

import { ACCENT_TEXT } from "common/constants/buttonTypes";
import { BUY_STATE, RENT_STATE, SELL_STATE } from "common/constants/searchTypes";
import { LoaderWrapper, PageMainContainer } from "common/styles";
import * as PATH from "common/constants/pathRoutes";
import Anchors from "common/constants/anchors";
import { addErrorNotification } from "../notifications";
import { appNotifications } from "../constants/notifications";

const {
    VALIDATION_PRICE_THRESHOLD_BUY,
    VALIDATION_PRICE_THRESHOLD_SELL,
    VALIDATION_PRICE_WATCHLIST_BUY,
    VALIDATION_PRICE_WATCHLIST_SELL,
    GRID_ISBN_VALIDATION_ERROR
} = appNotifications;

export const isServer = typeof window === "undefined";

/**
 * detect if page was called direct by url or by client side
 * need to prevent trigger getServerSideProps, so page behaviour will be like spa app
 * see link: https://github.com/vercel/next.js/discussions/19611#discussioncomment-972107
 */
export const isFirstServerCall = (req) => req?.url?.indexOf("/_next/data/") === -1;

export const REQUESTS_COUNT_LIMIT = 8;
export const REQUESTS_PRICES_TIMEOUT = 2000;
export const REQUESTS_WATCHLIST_TIMEOUT = 2000;
export const REQUESTS_AUTH_TIMEOUT = 500;

export const IMAGE_BLUR_PLACEHOLDER =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0" +
    "lEQVR42mO89B8AAqkB05ycXjIAAAAASUVORK5CYII=";

export const DEFAULT_BOOK_TITLE = "Book title unavailable";

export const MAX_GRID_ISBNS_COUNT = 20;

export const FETCH_WATCHLIST_TIMEOUT = 1000;

export const getTransformedLink = (content) => {
    // hosts for wordpress from env file
    let modContent = content.replace(/http(s)?:\/\/(www.)?bookscouter.com(\/)?/gi, "/");
    modContent = modContent.replace(/http(s)?:\/\/blog.bookscouter.com\/blog/gi, "/blog");
    modContent = modContent.replace(/http(s)?:\/\/(www.)?new.bksctrspro.com(\/)?/gi, "/");
    modContent = modContent.replace(/http(s)?:\/\/bsblog.wpenginepowered.com/gi, "/blog");
    modContent = modContent.replace("/blog/resources/", "/tips/");
    modContent = modContent.replace("/blog/resource_topic/", "/tips/topic/");
    modContent = modContent.replace(/\/resources\/\d{4}\/\d{1,2}\/\d{1,2}\//gi, "/tips/");

    return modContent;
};

export const getRandomItems = (data = [], count) => {
    const elementsWithImages = data.filter(({ book }) => isPresentValue(book.img));
    const dataForSorting = elementsWithImages.length >= count ? elementsWithImages : data;

    return dataForSorting.sort(() => Math.random() - 0.5).slice(0, count);
};

export const getQueryParamsByTab = (tabType, querySign = "?") => {
    if ([SELL_STATE, BUY_STATE, RENT_STATE].includes(tabType)) {
        return `${querySign}type=${tabType}`;
    }

    return "";
};

export const getAdvancedSearchQuery = (values) => {
    const query = Object.entries(values)
        .filter((value) => value[1])
        .map((value) => `${value[0]}=${value[1]}`)
        .join("&");
    return `${PATH.ADVANCED_SEARCH}?${query}`;
};

export const getSearchQueryString = ({ searchValue, activeSearchTab }) =>
    `${PATH.SEARCH}?query=${encodeURIComponent(searchValue)}${getQueryParamsByTab(
        activeSearchTab,
        "&"
    )}`;

export const validIdPattern = "[0-9]+";
export const validIsbnPatternWithoutDashes = "[0-9, X|x]{10,13}";
export const validIsbnPattern =
    // eslint-disable-next-line max-len
    "(?:ISBN(?:-1[03])?:? )?(?=[0-9X]{10}$|(?=(?:[0-9]+[- ]){3})[- 0-9X]{13}$|97[89][0-9]{10}$|(?=(?:[0-9]+[- ]){4})[- 0-9]{17}$)(?:97[89][- ]?)?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9X]";

export const upperFirst = (str) => str.slice(0, 1).toUpperCase() + str.slice(1, str.length);
export const isObject = (obj) => typeof obj === "object" && obj !== null;
export const lowerFirst = (str) => str.slice(0, 1).toLowerCase() + str.slice(1, str.length);
export const isEmptyValue = (value) => value === undefined || value === null || value === "";
export const isPresentValue = (value) => !isEmptyValue(value);

export const isValidIsbn = (isbn) => {
    const trimmedISBN = isbn.trim();
    return new RegExp(`^${validIsbnPattern}$`, "gmi").test(trimmedISBN);
};

export const isValidIsbnUrl = (url) =>
    new RegExp(`${PATH.BOOK}/${validIsbnPattern}$`, "gmi").test(url);
export const serializeFromApi = (obj) =>
    Object.keys(obj).reduce((acc, curr) => {
        return {
            ...acc,
            [lowerFirst(curr)]: isObject(obj[curr]) ? serializeFromApi(obj[curr]) : obj[curr]
        };
    }, {});

export const deserializeToApi = (obj) =>
    Object.keys(obj).reduce((acc, curr) => {
        return {
            ...acc,
            [upperFirst(curr)]: isObject(obj[curr]) ? deserializeToApi(obj[curr]) : obj[curr]
        };
    }, {});

export const copyToClipboard = (text) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
};
export const renderLoaderContent = ({ showBackground = true } = {}) => (
    <PageMainContainer showBackground={showBackground}>
        <LoaderWrapper>
            <Loader />
        </LoaderWrapper>
    </PageMainContainer>
);

export const generateQueryIsbnUrl = ({ isbns }) =>
    isbns
        ? isbns.map((isbn, index) => (index === 0 ? `?isbn=${isbn}` : `&isbn=${isbn}`)).join("")
        : "";

export const handleSignUpRecaptchaV3Verify = async ({
    executeRecaptchaCallback,
    action,
    callback = () => {}
}) => {
    if (!executeRecaptchaCallback) {
        // case if v3 recaptcha not available
        return;
    }

    if (isSignUpRecaptchaEnabled) {
        const token = await executeRecaptchaCallback(action);

        callback(token);
        return;
    }

    callback(null);
};

export const handleRecaptchaV3Verify = async ({
    executeRecaptchaCallback,
    action,
    callback = () => {}
}) => {
    if (!executeRecaptchaCallback) {
        // case if v3 recaptcha not available
        return;
    }

    if (isRecaptchaEnabled) {
        const token = await executeRecaptchaCallback(action);

        callback(token);
        return;
    }

    callback(null);
};

export const getRecaptchaTokenV2 = () =>
    Cookies.get("tokenV2") ? { tokenV2: encodeURIComponent(Cookies.get("tokenV2")) } : {};

export const getSignUpRecaptchaTokenV2 = () =>
    Cookies.get("signUpTokenV2")
        ? { tokenV2: encodeURIComponent(Cookies.get("signUpTokenV2")) }
        : {};

export const compareArr = (a1, a2) => {
    return a1.length === a2.length && a1.every((v, i) => v === a2[i]);
};

export const getTrimmedObject = (obj) => {
    const resultObj = {};

    Object.keys(obj).map((key) =>
        typeof obj[key] === "string"
            ? (resultObj[key] = obj[key].trim())
            : (resultObj[key] = obj[key])
    );

    return resultObj;
};

export const getHeaderHeight = () =>
    document.querySelector(`#${Anchors.HEADER}`)?.offsetHeight || 0;

export const gtmGetUser = ({ user, isAuth }) => {
    const userId = isAuth && user ? user.id : "anon";
    const proStatus = isAuth && user ? user.proStatus : null;
    const email = isAuth && user ? user.email : null;

    window.dataLayer.push({
        userId,
        proStatus
    });

    if (email && user.isProUser) {
        window.dataLayer.push({ event: "start_pw", pw_user_email: email });
    }
};

export const calculateCheckDigit13 = (isbn) => {
    let sum = 0;

    for (let i = 0; i < 12; i++) {
        const digit = parseInt(isbn[i], 10);
        sum += digit * (1 + 2 * (i % 2));
    }

    return ((10 - (sum % 10)) % 10).toString();
};

export const replace290IsbnFormat = (isbn) => {
    // replace 290/291 with 978/979 (used book codes)
    if (isbn.length === 13) {
        if (isbn.slice(0, 3) === "290") {
            isbn = `978${isbn.slice(3, 13)}`;
            const check = calculateCheckDigit13(isbn);
            return `${isbn.slice(0, 12)}${check}`;
        }
        if (isbn.slice(0, 3) === "291") {
            isbn = `979${isbn.slice(3, 13)}`;
            const check = calculateCheckDigit13(isbn);
            return `${isbn.slice(0, 12)}${check}`;
        }
    }

    return isbn;
};

export const scrollTo = (position, isSmooth = true) =>
    window.scrollTo({
        top: position - getHeaderHeight(),
        behavior: isSmooth ? "smooth" : undefined
    });

export const isTouchDevice = () => {
    return (
        "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
    );
};

export const getStringWithoutDashes = (isbn = "") => isbn.replace(/-/g, "");

export const parseMultipleGridIsbns = ({ isbns, withNotification = true }) => {
    return [
        ...new Set(
            isbns
                .split(/[\s,;]+/)
                .map(getStringWithoutDashes)
                .filter((isbn) => {
                    if (withNotification && !isValidIsbn(isbn) && isbn.length !== 0) {
                        addErrorNotification({
                            msg: GRID_ISBN_VALIDATION_ERROR(isbn)
                        });
                    }

                    return isValidIsbn(isbn);
                })
                .slice(-20)
        )
    ];
};

export const calculateSellAlertPrice = (price) => {
    if (isEmptyValue(price)) {
        return undefined;
    }

    const priceNumber = Number(price);

    if (priceNumber < 0) {
        return 1;
    }

    if (priceNumber <= 3) {
        return parseFloat((priceNumber + 1).toFixed(2));
    }

    if (priceNumber <= 20) {
        return parseFloat((priceNumber + 2).toFixed(2));
    }

    if (priceNumber <= 30) {
        return parseFloat((priceNumber * 1.1).toFixed(2)); // +10%
    }

    if (priceNumber <= 50) {
        return parseFloat((priceNumber * 1.08).toFixed(2)); // +8%
    }

    if (priceNumber <= 70) {
        return parseFloat((priceNumber * 1.05).toFixed(2)); // +5%
    }

    if (priceNumber <= 100) {
        return parseFloat((priceNumber * 1.04).toFixed(2)); // +4%
    }

    return parseFloat((priceNumber * 1.03).toFixed(2)); // +3%
};

export const calculateBuyAlertPrice = (price) => {
    if (isEmptyValue(price)) {
        return undefined;
    }

    const priceNumber = Number(price);

    if (priceNumber < 0) {
        return 1;
    }

    if (priceNumber <= 1) {
        return priceNumber;
    }

    if (priceNumber > 1 && priceNumber <= 3) {
        return parseFloat((priceNumber - 1).toFixed(2));
    }

    if (priceNumber <= 20) {
        return parseFloat((priceNumber - 2).toFixed(2));
    }

    if (priceNumber <= 30) {
        return parseFloat((priceNumber * 0.9).toFixed(2)); // -10%
    }

    if (priceNumber <= 50) {
        return parseFloat((priceNumber * 0.92).toFixed(2)); // -8%
    }

    if (priceNumber <= 70) {
        return parseFloat((priceNumber * 0.95).toFixed(2)); // -5%
    }

    if (priceNumber <= 100) {
        return parseFloat((priceNumber * 0.96).toFixed(2)); // -4%
    }

    return parseFloat((priceNumber * 0.97).toFixed(2)); // -3%
};

export const priceValidationConditions = ({ validationPrice, price }) => ({
    [SELL_STATE]: parseFloat(validationPrice) > parseFloat(price) || parseFloat(price) < 0.01,
    [BUY_STATE]: parseFloat(validationPrice) < parseFloat(price) || parseFloat(price) < 0.01
});

export const priceValidationNotifications = {
    [SELL_STATE]: VALIDATION_PRICE_THRESHOLD_SELL,
    [BUY_STATE]: VALIDATION_PRICE_THRESHOLD_BUY
};

export const priceWatchlistValidationNotifications = {
    [SELL_STATE]: VALIDATION_PRICE_WATCHLIST_SELL,
    [BUY_STATE]: VALIDATION_PRICE_WATCHLIST_BUY
};

export const decodeSearchTerm = (value) => {
    try {
        return decodeURIComponent(decodeURIComponent(value));
    } catch (error) {
        return value;
    }
};

export const formatNumberWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getBestBookPriceVendor = (booksPricesData, currentVendorId) =>
    Object.keys(booksPricesData)
        .map((isbn) => {
            const currentVendorByBook = booksPricesData[isbn]?.vendors.find(
                ({ id }) => id === parseInt(currentVendorId)
            );

            if (currentVendorByBook) {
                return {
                    price: parseFloat(currentVendorByBook.price),
                    vendor: currentVendorByBook,
                    coupons: currentVendorByBook.coupons
                };
            }

            return null;
        })
        .sort((a, b) => b?.price - a?.price)[0];

export const EXCLUDED_INDEX_ROUTES = [
    PATH.ACCOUNT,
    PATH.WATCHLIST,
    PATH.PRO_TOOLS,
    PATH.API,
    PATH.BULK_LOOKUP,
    PATH.BULK,
    PATH.DEALS,
    PATH.PRICE_HISTORY,
    PATH.HIGH_VALUE,
    PATH.HEALTH
];

export const getContentAfterReactHtmlParser = ({ content, onButtonClickHandler }) => {
    const transformHtmlToReactComponents = (node, index) => {
        if (node.type === "tag" && node.name === "a" && node.attribs?.href === PATH.CONTACT) {
            return (
                <Button
                    key={index}
                    variant={ACCENT_TEXT}
                    label={processNodes(node.children, transformHtmlToReactComponents)}
                    onClick={onButtonClickHandler}
                />
            );
        }
    };

    return ReactHtmlParser(content, {
        transform: transformHtmlToReactComponents
    });
};

export const parseCookies = (cookiesStr = "") =>
    cookiesStr
        .split(";")
        .map((item) => item.split("="))
        .reduce(
            (acc, curr) => ({
                ...acc,
                [decodeURIComponent(curr[0]?.trim())]: decodeURIComponent(curr[1]?.trim())
            }),
            {}
        );

export const assertDefined = (value) => {
    if (value === undefined || value === null) {
        throw new Error("Should not be a nullable value");
    }
};

export const isRecaptchaEnabled = process.env.NEXT_PUBLIC_FEATURE_RECAPTCHA_ENABLED === "1";

export const isSignUpRecaptchaEnabled =
    process.env.NEXT_PUBLIC_FEATURE_RECAPTCHA_SIGN_UP_ENABLED === "1";

const validateDateSeen = (dateSeen) => {
    // This is here in case the DateSeen is ever invalid according to Moment
    // Realistically, this should never be run
    if (!moment(dateSeen)._isValid) {
        if (dateSeen.indexOf("W53") > -1) {
            return `${parseInt(dateSeen.split("W53")[0], 10) + 1}-W01`;
        }
    }

    return moment(dateSeen);
};

export const getTwoDimensionalStructure = (arr, xDimension, yDimension, withVendorLogo) =>
    arr.map((item) => ({
        x: validateDateSeen(item[xDimension]),
        y: item[yDimension],
        ...(withVendorLogo && {
            vendorName: item.vendorName,
            vendorLogoPath: item.vendorLogoPath
        })
    }));

export const getFormattedPhone = (phone) => {
    if (phone) {
        const formattedPhone = String(phone)
            .split("")
            .map((letter, index) => {
                if (index + 1 === 3 || index + 1 === 6) {
                    return `${letter}-`;
                }

                return letter;
            })
            .join("");

        return formattedPhone;
    }

    return phone;
};
