import { isEmptyValue } from "common/utils";
import { SUCCESS_STATUS } from "components/BuyOrRentCalculator/constants";

export const buyVsRentCalculatorFormatter = ({ data }) => {
    if (data.status === SUCCESS_STATUS) {
        const buyPrice = parseFloat(data.buyOffer.price + data.buyOffer.shipping).toFixed(2);
        const rentPrice = parseFloat(data.rentOffer.price + data.rentOffer.shipping).toFixed(2);
        const cost = parseFloat(buyPrice - data.predictedBuybackPrice).toFixed(2);

        return {
            advice: { buyOrRent: data.advice.buyOrRent, confidence: data.advice.confidence },
            requestStatus: data.status,
            isbn: data.isbn,
            offer: {
                buy: {
                    ...data.buyOffer,
                    price: buyPrice,
                    cost
                },
                rent: {
                    ...data.rentOffer,
                    price: rentPrice
                }
            },
            buybackPredictionPrice: isEmptyValue(data.predictedBuybackPrice)
                ? 0
                : parseFloat(data.predictedBuybackPrice).toFixed(2)
        };
    }

    return { requestStatus: data.status };
};
