// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/browser";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const replay = new Sentry.Replay({
    maskAllText: false,
    blockAllMedia: false,
    maskAllInputs: false
});

if (process.env.NODE_ENV === "production") {
    const sentrySampleRate = process.env.NEXT_PUBLIC_SENTRY_ENV === "production" ? 0.01 : 0.1;

    Sentry.init({
        dsn: SENTRY_DSN,
        environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
        integrations: [replay],

        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: sentrySampleRate,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: sentrySampleRate,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: sentrySampleRate
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    });
}
