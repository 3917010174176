import React from "react";
import dynamic from "next/dynamic";

import { isEmptyValue, renderLoaderContent } from "common/utils";
import ModalTypes from "common/constants/modalTypes";

const config = {
    [ModalTypes.CONTACT]: dynamic(() => import("components/forms/Contact"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.VENDORS_FEEDBACK]: dynamic(() => import("components/forms/VendorsFeedback"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.LOG_IN]: dynamic(() => import("components/forms/Login"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.SIGN_UP]: dynamic(() => import("components/forms/SignUp"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.RESET_PASSWORD]: dynamic(() => import("components/forms/ResetPassword"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.SIGN_UP_PRO]: dynamic(() => import("components/stripeForms/SignUpPro"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.MULTIPLE_ISBNS]: dynamic(() => import("components/forms/MultipleIsbns"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.REPORT_PRICING_ISSUE]: dynamic(
        () => import("components/forms/ReportPricingIssue"),
        {
            loading: () => renderLoaderContent(),
            ssr: false
        }
    ),
    [ModalTypes.PRICE_HISTORY]: dynamic(() => import("components/PriceHistory"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.PRICE_ALERT]: dynamic(() => import("components/forms/PriceAlert"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.GRID_LIST_REDIRECT]: dynamic(() => import("components/ListViewRedirect"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.MULTIPLE_WATCHLIST]: dynamic(() => import("components/forms/MultipleWatchlist"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.UPDATE_PASSWORD]: dynamic(() => import("components/forms/UpdatePassword"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.PRICE_HISTORY_BUY]: dynamic(
        () => import("components/PriceHistory/PriceHistoryBuy"),
        {
            loading: () => renderLoaderContent(),
            ssr: false
        }
    ),
    [ModalTypes.CANCELLATION]: dynamic(() => import("components/forms/Cancellation"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.CHANGE_PAYMENT]: dynamic(() => import("components/stripeForms/ChangePayment"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.REPORT_SELLER]: dynamic(() => import("components/forms/ReportSeller"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.DEALS_FILTER_VENDORS]: dynamic(
        () => import("components/forms/DealsVendorsFilters"),
        {
            loading: () => renderLoaderContent(),
            ssr: false
        }
    ),
    [ModalTypes.EMAIL_SETTINGS]: dynamic(() => import("components/forms/EmailSettings"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.BUY_VS_RENT]: dynamic(() => import("components/BuyOrRentCalculator"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.DEALS_DETAILS]: dynamic(() => import("components/DealsDetailsView"), {
        loading: () => renderLoaderContent(),
        ssr: false
    }),
    [ModalTypes.CLEAR_RECENTLY_HISTORY]: dynamic(() => import("components/forms/ClearRecently"), {
        loading: () => renderLoaderContent(),
        ssr: false
    })
};

export const getModalContent = ({ type, children, childrenProps }) => {
    if (isEmptyValue(type)) {
        return React.Children.map(children, (child) => React.cloneElement(child, childrenProps));
    }

    if (isEmptyValue(config[type])) {
        throw new Error("Modal config must have component for special type");
    }

    const Component = config[type];

    return <Component {...childrenProps} />;
};
