import format from "date-fns/format";
import { isPresentValue } from "common/utils";

import { metaFormatterFromApi, memberArrayFormatterFromApi } from "../common";
import { CUSTOM_LOGO_VENDORS } from "common/constants/vendors";

export const vendorFormatterFromApi = (vendor) => {
    let returnShippingCost = vendor.returnShippingCost;

    if (Number(returnShippingCost)) {
        returnShippingCost = `${returnShippingCost}$`;
    } else if (!returnShippingCost) {
        returnShippingCost = null;
    }

    return {
        id: vendor.id,
        name: vendor.name,
        slug: vendor.slug,
        printingAllowed: vendor.printingAllowed || null,
        returnPolicy: vendor.returnPolicy || null,
        formats: vendor.formats || null,
        compatibleDevices: vendor.compatibleDevices || null,
        expectedDeliveryTime: vendor.expectedDeliveryTime || null,
        shipsFrom: vendor.shipsFrom || null,
        returnShippingCost,
        rating: Number.parseFloat(vendor.rating) || 0,
        ratingCount: Number.parseInt(vendor.ratingCount, 10),
        positiveFeedbackCount: Number.parseInt(vendor.positiveFeedbackCount, 10),
        type: vendor.type,
        customLogoPath: CUSTOM_LOGO_VENDORS[vendor.slug]
            ? `/images/logos/vendors/logo-custom-${vendor.slug}.png`
            : null,
        logoPath: `/images/logos/vendors/logo-${vendor.slug}.png`,
        iconPath: `/images/logos/vendors/icon-${vendor.slug}.png`,
        featuredInBulkComparison: vendor?.bulkInfo?.inBulkComparison,
        allowBulk: vendor?.bulkInfo?.allowBulk,
        minOrder: vendor.minimumOrder,
        buySell: vendor.buySell,
        noReferrer: vendor.noreferrer,
        get slugForUrl() {
            return `${this.id}${isPresentValue(this.slug) ? `-${this.slug}` : ""}`;
        }
    };
};

export const vendorsFormatterFromApi = (data) =>
    memberArrayFormatterFromApi(data).map(vendorFormatterFromApi);

export const vendorsSellFormatterFromApi = (data) =>
    memberArrayFormatterFromApi(data).reduce(
        (acc, curr) => {
            const formattedVendor = vendorFormatterFromApi(curr);
            const key = formattedVendor.type === "PREFERRED" ? "preferred" : "normal";

            return { ...acc, [key]: [...acc[key], formattedVendor] };
        },
        { normal: [], preferred: [] }
    );

export const vendorsBulkLookupFormatterFromApi = (data) =>
    vendorsFormatterFromApi(data)
        .filter((vendor) => vendor.allowBulk)
        .map((vendor) => ({ ...vendor, isSelected: true }));

export const vendorsGridViewSellFormatterFromApi = (data) =>
    memberArrayFormatterFromApi(data)
        .map(vendorFormatterFromApi)
        .sort((curVendor, nextVendor) => curVendor.id - nextVendor.id);

export const vendorFeedbackItemFormatterFromApi = (feedback) => ({
    id: feedback.id,
    comments: feedback.comments || null,
    contact: feedback.contact || null,
    timestamp:
        typeof feedback.timestamp === "string"
            ? format(new Date(feedback.timestamp), "MMM dd, yyyy")
            : null,
    rating: Number.parseFloat(feedback.rating) || 0
});

export const vendorsFeedbackFormatterToApi = ({
    rating,
    comment,
    email,
    orderNumber,
    vendorId
}) => ({
    rating,
    orderNumber,
    comments: comment,
    email,
    vendor: `/v4/vendors/${vendorId}`
});

export const vendorFeedbackFormatterFromApi = (data) => ({
    data: memberArrayFormatterFromApi(data).map(vendorFeedbackItemFormatterFromApi),
    ...metaFormatterFromApi(data)
});

export const vendorDetailsFormatterFromApi = (vendor) => ({
    description: vendor.description || null,
    establishedDate: vendor.establishedDate ? new Date(vendor.establishedDate).getFullYear() : null,
    listedOnBookScouterSince: vendor.listedOnBookScouterSince
        ? new Date(vendor.listedOnBookScouterSince).getFullYear()
        : null,
    shippingInformation: vendor.shippingInformation,
    paymentInformation: vendor.paymentInformation,
    minBuybackOrder: vendor.minBuybackOrder ? `$${vendor.minBuybackOrder}` : null,
    paysByACH: vendor.paysByACH || null,
    paysByCheck: vendor.paysByCheck || null,
    paysByCreditCard: vendor.paysByCreditCard || null,
    paysByVenmo: vendor.paysByVenmo || null,
    paysByZelle: vendor.paysByZelle || null,
    paysByStoreCredit: vendor.paysByStoreCredit || null,
    paysByPayPal: vendor.paysByPayPal || null,
    paysShipping: vendor.paysShipping || null,
    providesShippingLabel: vendor.providesShippingLabel || null,
    shipsFedEx: vendor.shipsFedEx || null,
    shipsUPS: vendor.shipsUPS || null,
    shipsUSPS: vendor.shipsUSPS || null,
    vendorId: vendor.id || null,
    website: vendor.website || null,
    email: vendor.email || null,
    phone: vendor.phone || null,
    address: vendor.address || null,
    contact: vendor.contact || null
});
