import { useCallback, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import Quagga from "@ericblade/quagga2";

import { isPresentValue } from "common/utils";
import { families, sizes } from "common/styles/config";
import { setIsScanning } from "contexts/App/actions";
import { useAppContext } from "contexts";

const defaultLocatorSettings = {
    patchSize: "medium",
    halfSample: true
};

const defaultDecoders = ["ean_reader"];

const getMedian = (errors) => {
    const errorsList = [...errors].sort((a, b) => a - b);
    const half = Math.floor(errorsList.length / 2);

    if (errorsList.length % 2 === 1) {
        return errorsList[half];
    }

    return (errorsList[half - 1] + errorsList[half]) / 2;
};

const getMedianOfCodeErrors = (decodedCodes) => {
    const errors = decodedCodes.reduce((acc, x) => {
        if (isPresentValue(x.error)) {
            return [...acc, x.error];
        }

        return acc;
    }, []);

    const medianOfErrors = getMedian(errors);

    return medianOfErrors;
};

const MobileScannerCore = ({
    onDetected,
    scannerRef,
    onError,
    cameraId,
    facingMode,
    constraints,
    locator,
    numOfWorkers,
    decoders,
    locate = true
}) => {
    const [, dispatch] = useAppContext();
    const router = useRouter();

    const errorCheck = useCallback(
        (result) => {
            if (!onDetected) {
                return;
            }

            const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);

            if (err < 0.08) {
                onDetected(result.codeResult.code);
            }
        },
        [onDetected]
    );

    const handleProcessed = (result) => {
        const drawingCtx = Quagga.canvas.ctx.overlay;
        const drawingCanvas = Quagga.canvas.dom.overlay;
        drawingCtx.font = `${sizes.em.large} ${families.text}`;
        drawingCtx.fillStyle = "green";

        if (result) {
            if (result.boxes) {
                drawingCtx.clearRect(
                    0,
                    0,
                    parseInt(drawingCanvas.getAttribute("width"), 10),
                    parseInt(drawingCanvas.getAttribute("height"), 10)
                );

                result.boxes.forEach((box) => {
                    if (box !== result.box) {
                        Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                            color: "transparent"
                        });
                    }
                });
            }

            if (result.box) {
                Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
                    color: "transparent"
                });
            }
        }
    };

    const scannerStopFunction = () => {
        Quagga.offDetected(errorCheck);
        Quagga.offProcessed(handleProcessed);
        Quagga.stop();
        setIsScanning({ dispatch, isScanning: false });
    };

    useLayoutEffect(() => {
        Quagga.init(
            {
                inputStream: {
                    type: "LiveStream",
                    constraints: {
                        ...constraints,
                        ...(isPresentValue(cameraId) ? { deviceId: cameraId } : { facingMode })
                    },
                    target: scannerRef.current
                },
                locator: {
                    patchSize: "medium",
                    halfSample: true
                },
                numOfWorkers,
                decoder: { readers: decoders },
                locate
            },
            (err) => {
                Quagga.onProcessed(handleProcessed);

                if (err) {
                    scannerStopFunction();
                    onError(err);
                    return;
                }

                if (scannerRef && scannerRef.current) {
                    Quagga.start();
                    // Fix for android back-button and scanner stopping
                    window.addEventListener("popstate", scannerStopFunction);
                    router.events.on("routeChangeStart", scannerStopFunction);
                }
            }
        );

        Quagga.onDetected(errorCheck);

        return () => {
            Quagga.offDetected(errorCheck);
            Quagga.offProcessed(handleProcessed);
            Quagga.stop();
            router.events.off("routeChangeStart", scannerStopFunction);
        };
    }, [cameraId, onDetected, scannerRef, errorCheck, constraints, locator, decoders, locate]);

    return null;
};

MobileScannerCore.propTypes = {
    onDetected: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    scannerRef: PropTypes.object.isRequired,
    cameraId: PropTypes.string,
    facingMode: PropTypes.string,
    constraints: PropTypes.object,
    locator: PropTypes.object,
    numOfWorkers: PropTypes.number,
    decoders: PropTypes.array,
    locate: PropTypes.bool
};

MobileScannerCore.defaultProps = {
    locator: defaultLocatorSettings,
    numOfWorkers: 0,
    decoders: defaultDecoders
};

export default MobileScannerCore;
