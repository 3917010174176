import { isPresentValue } from "common/utils";

import {
    facetsFormatterFromApi,
    memberArrayFormatterFromApi,
    metaFormatterFromApi
} from "../common";

const bookBaseFormatter = (book) => ({
    img: book.image.replace("_SL75_", "_SL200_"),
    title: isPresentValue(book.title) ? book.title : "No title",
    author: book.author instanceof Array && book.author.length ? book.author.join("; ") : "Unknown",
    isbn10: book.isbn10,
    isbn13: book.isbn13,
    publisher: book.publisher,
    binding: book.binding,
    edition: book.edition,
    released: isPresentValue(book.publishedDate) ? book.publishedDate : null,
    slug: book.slug,
    minBuyPrice: book.minBuyPrice ? book.minBuyPrice.toFixed(2) : 0,
    countOfBuyOffers: book.countOfBuyOffers
});

export const booksFormatterFromApi = (data) => ({
    data: memberArrayFormatterFromApi(data).map(bookBaseFormatter),
    filterData: facetsFormatterFromApi(data),
    ...metaFormatterFromApi(data)
});
