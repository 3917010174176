import { isPresentValue } from "common/utils";
import React from "react";
import Head from "next/head";
import PropTypes from "prop-types";

import * as PATH from "common/constants/pathRoutes";
import { SELL_STATE, BUY_STATE, RENT_STATE, MIX_STATE } from "common/constants/searchTypes";
import { useResourceType } from "common/hooks";

const UNAVAILABLE_TITLE = "book title unavailable";

const getSchemaRating = ({ book }) => ({
    aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: book.aggregateRating.ratingValue,
        ratingCount: book.aggregateRating.ratingCount,
        bestRating: book.aggregateRating.bestRating
    }
});

const getLowBuyPrice = ({ historyBestPrices }) => {
    if (historyBestPrices?.buy.price) {
        return {
            offers: {
                "@type": "AggregateOffer",
                lowPrice: Number(historyBestPrices?.buy.price),
                priceCurrency: "USD"
            }
        };
    }

    return {};
};

const getMixSchema = ({ book, isbn, historyBestPrices }) => ({
    "@context": "https://schema.org",
    "@type": "DataFeed",
    dataFeedElement: [
        {
            "@context": "https://schema.org",
            "@type": ["Book", "Product"],
            name: book.title,
            gtin13: book.isbn13,
            bookEdition: book.edition,
            ...(isPresentValue(book.pagesCount) && { numberOfPages: book.pagesCount }),
            publisher: book.publisher,
            bookFormat: book.binding,
            inLanguage: "en",
            datePublished: book.released,
            image: book.previewImg,
            thumbnailUrl: book.img,
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/${isbn}-${book.slug}`,
            provider: {
                "@type": "Organization",
                name: "BookScouter",
                url: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
                logo: `${process.env.NEXT_PUBLIC_BASE_URL}/icons/logo-full.svg`
            },
            ...getSchemaRating({ book }),
            ...getLowBuyPrice({ historyBestPrices })
        }
    ]
});

export const getSchemaForBookPage = ({ type, book, isbn, historyBestPrices }) => {
    switch (type) {
        case MIX_STATE:
            return getMixSchema({ book, isbn, historyBestPrices });

        default:
            return getSchemaReviewsAndRatings({ book, isbn });
    }
};

const getSchemaReviewsAndRatings = ({ book, isbn }) => {
    const isbnDescription = book.isbn13 ? "ISBN13" : "ISBN10";

    return {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: book.title,
        description: `${book.title} by ${book.author}, ${isbnDescription} ${isbn}`,
        ...getSchemaRating({ book }),
        isbn: isbn,
        sku: isbn,
        image: book.img,
        ...(isPresentValue(book.publisher) && {
            brand: {
                "@type": "Brand",
                name: book.publisher
            }
        })
    };
};

const getOpenGrapghBookTitle = ({ isbn13, title }) => ({
    [SELL_STATE]: `Sell ${isbn13} ${title.slice(0, 45)}... for the best price`,
    [BUY_STATE]: `Buy ${isbn13} ${title.slice(0, 45)}... for the best price`,
    [RENT_STATE]: `Rent ${isbn13} ${title.slice(0, 45)}... for the best price`
});

const getSlicedString = (str, limit, withDot) => {
    if (str.length > limit) {
        let position = 0;

        str.split("").forEach((letter, index) => {
            if (letter === " " && index <= limit) {
                position = index;
            }
        })

        return `${str.slice(0, position)}`;
    }
    return `${str.slice(0, limit)}${withDot ? "." : ""}`;
};

const getTitle = (title) => {
    if (!title || title.toLowerCase() === UNAVAILABLE_TITLE) {
        return "";
    }

    const str = `${getSlicedString(title, 30)}`;

    if (str.slice(-1) === " ") {
        return str.slice(0, -1);
    }

    return str;
};

const getAuthor = (author) => {
    if (!author) {
        return "";
    }

    const str = ` by ${getSlicedString(author, 24)}`;
    if (str.slice(-1) === ",") {
        return str.slice(0, -1);
    }

    return str;
};

export const getReleasedDate = (releasedDate) => {
    if (!releasedDate) {
        return "";
    }

    const date = new Date(releasedDate);

    const currentDate = date.getDate();
    const currentMonth = date.toLocaleString("en-GB", { month: "long" });
    const currentYear = date.getFullYear();

    return `${currentMonth} ${currentDate}, ${currentYear}`;
};

const getMetaDescription = ({
    isbn13,
    isbn10,
    title,
    publisher,
    binding,
    edition,
    released,
    author
}) => {
    const str = `Best prices for${publisher ? ` ${publisher}` : ""} book ISBN ${
        isbn10 || isbn13
    }${getAuthor(author)}${binding ? ` in ${binding.toLowerCase()}` : ""}.`;

    if (!edition && !title && !released) {
        return str;
    }

    if (!edition && !title && released) {
        return `${str} See FAQ about this book ${
            released ? `released ${getReleasedDate(released)} ` : ""
        }and compare offers instantly!`;
    }

    return `${str} See FAQ about ${edition ? `edition ${edition} of ` : ""}${getTitle(title)} ${
        released ? `released ${getReleasedDate(released)} ` : ""
    }and compare offers instantly!`;
};

export const BookSeo = ({
    isbn13,
    title,
    slug,
    author,
    img,
    publisher,
    isbn10,
    binding,
    edition,
    released
}) => {
    const { type } = useResourceType();

    return (
        <Head>
            <title key="title">{`${isbn13}: ${title} | BookScouter.com`}</title>
            <link
                rel="canonical"
                key="canonical"
                href={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.BOOK}/${isbn13}-${slug}`}
            />
            <meta
                name="description"
                key="description"
                content={getMetaDescription({
                    isbn10,
                    isbn13,
                    publisher,
                    author,
                    released,
                    title,
                    edition,
                    binding
                })}
            />
            <meta
                property="og:title"
                key="og:title"
                content={getOpenGrapghBookTitle({ isbn13, title })[type]}
            />
            <meta
                property="og:description"
                key="og:description"
                content={getMetaDescription({
                    isbn10,
                    isbn13,
                    publisher,
                    author,
                    released,
                    title,
                    edition,
                    binding
                })}
            />
            <meta property="og:type" key="og:type" content="website" />
            <meta property="og:image" key="og:image" content={img} />
            <meta
                property="og:url"
                key="og:url"
                content={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.BOOK}/${isbn13}-${slug}`}
            />
        </Head>
    );
};

BookSeo.propTypes = {
    isbn13: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    isbn10: PropTypes.string,
    publisher: PropTypes.string,
    edition: PropTypes.string,
    binding: PropTypes.string,
    released: PropTypes.string,
    author: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired
};
