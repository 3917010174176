import getQueriesConfig from "./config";

import { pressDataFormatterFromApi } from "./formatters/press";

const createWpRequests = () => {
    const { clientQueryConfig } = getQueriesConfig({
        url: process.env.NEXT_PUBLIC_WP_API_URL
    });

    const getFaqQuestionsData = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("/faqs", { params });

        return response.data;
    };

    const getPagesData = async ({ params, withFormat } = {}) => {
        const response = await clientQueryConfig.get("/pages", { params });

        return pressDataFormatterFromApi(response.data[0], withFormat);
    };

    const getPostsData = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("/posts", { params });

        return response.data;
    };

    const getCategoriesData = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("/categories", { params });

        return response.data;
    };

    return {
        getFaqQuestionsData,
        getPagesData,
        getPostsData,
        getCategoriesData
    };
};

export default createWpRequests;
