export const breakpointsValues = {
    minPhone: 320,
    minPhoneAlbum: 480,
    mediumPhone: 400,
    largePhone: 600,
    tablet: 740,
    desktopWidth: 850,
    desktopPlusWidth: 1100,
    desktopSuperWidth: 1200,
    mobileM: 375
};
export const breakpoints = {
    minPhone: `${breakpointsValues.minPhone}px`,
    minPhoneAlbum: `${breakpointsValues.minPhoneAlbum}px`,
    mediumPhone: `${breakpointsValues.mediumPhone}px`,
    largePhone: `${breakpointsValues.largePhone}px`,
    tablet: `${breakpointsValues.tablet}px`,
    desktopWidth: `${breakpointsValues.desktopWidth}px`,
    desktopPlusWidth: `${breakpointsValues.desktopPlusWidth}px`,
    desktopSuperWidth: `${breakpointsValues.desktopSuperWidth}px`,
    mobileM: `${breakpointsValues.mobileM}px`
};

export const families = {
    text: "Roboto, Tahoma, Helvetica, sans-serif",
    headline: "Oswald, Arial Narrow, Impact, sans-serif"
};

export const sizes = {
    em: {
        small: "0.750rem", // 12px
        base: "0.875rem", // 14px
        medium: "1rem", // 16px
        large: "1.125rem", // 18px
        extraLarge: "1.25rem" // 20px
    }
};

export const weights = {
    base: 400,
    medium: 500,
    semiBold: 600,
    bold: 700
};

export const colors = {
    mainOrange: "#f15726",
    mainOrangeHover: "#d63e0e",
    mainBlue: "#50b5c3",
    mainBlueHover: "#6bafb9"
};
