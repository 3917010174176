import React from "react";
import Head from "next/head";

import { SUPPORT_EMAIL } from "common/constants/commonStrings";
import * as PATH from "common/constants/pathRoutes";

import { MainSeo, MainBuySeo, MainRentSeo } from "modules/Main/seo";
import { TermsSeo } from "modules/Terms/seo";
import { PressSeo } from "modules/Press/seo";
import { PrivacySeo } from "modules/Privacy/seo";
import { FaqSeo } from "modules/Faq/seo";
import { AffiliateSeo } from "modules/Affiliate/seo";
import { ScholarshipSeo } from "modules/Scholarship/seo";
import { WidgetSeo } from "modules/Widget/seo";
import { VendorsListSeo } from "modules/Vendors/subModules/VendorsListPage/seo";
import { VendorDetailsSeo } from "modules/Vendors/subModules/VendorDetails/seo";
import { ProSeo } from "modules/Tools/subModules/Pro/seo";
import { ProToolsSeo } from "modules/Tools/subModules/ProTools/seo";
import { BookSeo } from "modules/Book/seo";
import BookstoresLandingSeo from "modules/Bookstores/subModules/BookstoresLanding/seo";
import { AuthorsSeo, PublishersSeo, CategoriesSeo } from "modules/Categories/seo";
import { ReviewsSeo } from "modules/Reviews/seo";

// eslint-disable-next-line max-len
import BookstoresByStateLandingSeo from "modules/Bookstores/subModules/BookstoresByStateLanding/seo";
import { isPresentValue } from "common/utils";
import { SEARCH } from "common/constants/pathRoutes";
import { AuthorSeo, CategorySeo, PublisherSeo } from "modules/CategoryBooks/seo";
import { AdvancedSearchSeo } from "modules/Search/seo/AdvancedSearchSeo";

const BaseSeo = () => (
    <Head>
        <title key="title">
            {"Sell textbooks and used books - Buyback comparison | BookScouter.com"}
        </title>
        <link rel="canonical" key="canonical" href={process.env.NEXT_PUBLIC_BASE_URL} />
        <meta
            name="description"
            key="description"
            content={
                "Sell your old textbooks and used books online at " +
                "the best price with BookScouter. We compare prices from 30+ vendors. " +
                "Single & multi ISBN lookup. Try now!"
            }
        />
        <meta
            property="og:title"
            key="og:title"
            content="Buy & Sell Textbooks and Used Books - Price Comparison"
        />
        <meta
            property="og:description"
            key="og:description"
            content={
                "Sell your old textbooks and used books online at " +
                "the best price with BookScouter. We compare prices from 30+ vendors. " +
                "Single & multi ISBN lookup. Try now!"
            }
        />
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
        <meta property="og:url" key="og:url" content={process.env.NEXT_PUBLIC_BASE_URL} />
    </Head>
);

export const getSeoTags = ({ path, prefetchedData }) => {
    if (path === PATH.MAIN) {
        return <MainSeo />;
    }

    if (path === PATH.BUY) {
        return <MainBuySeo />;
    }

    if (path === PATH.RENT) {
        return <MainRentSeo />;
    }

    if (path === PATH.TERMS) {
        return <TermsSeo />;
    }

    if (path === PATH.SCHOLARSHIP) {
        return <ScholarshipSeo />;
    }

    if (path === PATH.PRESS) {
        return <PressSeo />;
    }

    if (path === PATH.PRIVACY) {
        return <PrivacySeo />;
    }

    if (path === PATH.FAQ) {
        return <FaqSeo />;
    }

    if (path.includes(PATH.ADVANCED_SEARCH)) {
        return <AdvancedSearchSeo />;
    }

    if (path === PATH.AFFILIATES) {
        return <AffiliateSeo />;
    }

    if (path === PATH.WIDGET) {
        return <WidgetSeo />;
    }

    if (path === PATH.VENDORS) {
        return <VendorsListSeo />;
    }

    if (path.includes(PATH.VENDORS) && path !== PATH.ACCOUNT_VENDORS) {
        return <VendorDetailsSeo {...prefetchedData.vendorData} />;
    }

    if (path === PATH.PRO) {
        return <ProSeo />;
    }

    if (path === PATH.PRO_TOOLS) {
        return <ProToolsSeo />;
    }

    if (path.includes(`${PATH.BOOK}/`) && isPresentValue(prefetchedData.book)) {
        return (
            <BookSeo
                {...prefetchedData.book}
                historyBestPrices={prefetchedData?.historyBestPrices}
            />
        );
    }

    if (path === PATH.COLLEGE_BOOKSTORES) {
        return <BookstoresLandingSeo />;
    }
    if (path.includes(`${PATH.COLLEGE_BOOKSTORES}/`) && isPresentValue(prefetchedData.stateName)) {
        return <BookstoresByStateLandingSeo stateName={prefetchedData.stateName} />;
    }
    if (path === PATH.AUTHORS) {
        return <AuthorsSeo />;
    }
    if (path === PATH.PUBLISHERS) {
        return <PublishersSeo />;
    }
    if (path === PATH.CATEGORIES) {
        return <CategoriesSeo />;
    }
    if (path.includes(`${PATH.AUTHOR}/`) && isPresentValue(prefetchedData.category)) {
        return <AuthorSeo {...prefetchedData} />;
    }
    if (path.includes(`${PATH.PUBLISHER}/`) && isPresentValue(prefetchedData.category)) {
        return <PublisherSeo {...prefetchedData} />;
    }
    if (path.includes(`${PATH.CATEGORY}/`) && isPresentValue(prefetchedData.category)) {
        return <CategorySeo {...prefetchedData} />;
    }
    if (path === PATH.REVIEWS) {
        return <ReviewsSeo />;
    }

    return <BaseSeo />;
};

export const baseOrgSchema = {
    "@context": "https://schema.org",
    "@graph": [
        {
            "@type": "Organization",
            name: "BookScouter",
            description:
                "BookScouter.com is a comparison shopping website that helps buy, sell, and rent " +
                "textbooks and used books online.",
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
            logo: `${process.env.NEXT_PUBLIC_BASE_URL}/icons/logo-full.svg`,
            email: SUPPORT_EMAIL,
            legalName: "BOOKSCOUTER LLC",
            address: {
                "@type": "PostalAddress",
                addressCountry: "US",
                postalCode: "19802-4447",
                streetAddress: "2810 N Church St"
            },
            founder: {
                "@type": "Person",
                name: "Brandon Checketts",
                gender: "Male",
                jobTitle: "Founder",
                image: "https://avatars.githubusercontent.com/u/3952446?v=4",
                sameAs: [
                    "https://twitter.com/bchecketts",
                    "https://www.linkedin.com/in/bchecketts/"
                ]
            },
            foundingDate: "2007-08-06",
            sameAs: [
                "https://en.wikipedia.org/wiki/BookScouter.com",
                "https://www.crunchbase.com/organization/bookscouter",
                "https://www.facebook.com/BookScouter/",
                "https://www.linkedin.com/company/bookscouter/",
                "https://twitter.com/bookscoutercom",
                "https://www.youtube.com/c/BookscouterCom"
            ],
            contactPoint: [
                {
                    "@type": "ContactPoint",
                    contactType: "customer service",
                    email: SUPPORT_EMAIL,
                    url: `${process.env.NEXT_PUBLIC_BASE_URL}/`
                }
            ]
        },
        {
            "@type": "WebSite",
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/`,
            potentialAction: {
                "@type": "SearchAction",
                target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                        `${process.env.NEXT_PUBLIC_BASE_URL}${SEARCH}?` +
                        "query={search_term_string}"
                },
                "query-input": "required name=search_term_string"
            }
        }
    ]
};
