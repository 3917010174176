import React from "react";
import Link from "next/link";
import * as PATH from "common/constants/pathRoutes";
import { SELL_STATE, BUY_STATE, RENT_STATE } from "common/constants/searchTypes";
import { generateQueryIsbnUrl, getQueryParamsByTab, getSearchQueryString } from "common/utils";
import * as S from "../Header.styled";
export const getProToolMenuConfig = ({
  isProUser,
  router
}) => {
  return {
    label: "Pro Tools",
    path: isProUser ? PATH.PRO_TOOLS : PATH.PRO,
    onlyOnMobileShow: true,
    isActive: router.asPath === PATH.PRO || router.asPath === PATH.PRO_TOOLS || router.asPath.includes(PATH.DEALS) || router.asPath.includes(PATH.BULK_LOOKUP) || router.asPath.includes(`${PATH.BULK}/`) || router.asPath.includes(PATH.HIGH_VALUE) || router.asPath.includes(PATH.PRICE_HISTORY),
    id: "Pro Tools menu link",
    subMenuConfig: {
      show: isProUser,
      links: [{
        label: "Deals",
        path: PATH.DEALS,
        isActive: router.asPath.includes(PATH.DEALS),
        id: "Deals menu link"
      }, {
        label: "Bulk Liquidation",
        path: PATH.BULK_LOOKUP,
        isActive: router.asPath.includes(PATH.BULK_LOOKUP) || router.asPath.includes(`${PATH.BULK}/`),
        id: "Bulk Lookup menu link"
      }, {
        label: "High Value Books",
        path: PATH.HIGH_VALUE,
        isActive: router.asPath.includes(PATH.HIGH_VALUE),
        id: "High-Value Books menu link"
      }, {
        label: "Historic Pricing",
        path: PATH.PRICE_HISTORY,
        isActive: router.asPath.includes(PATH.PRICE_HISTORY),
        id: "Historic Pricing menu link"
      }]
    }
  };
};
export const navConfig = ({
  isProUser,
  isMainPage,
  router,
  searchTab,
  searchValue,
  parsedIsbns = []
}) => [{
  label: "Home",
  path: PATH.MAIN,
  onlyOnMobileShow: true,
  isActive: router.asPath === PATH.MAIN,
  id: "Home link"
}, getProToolMenuConfig({
  isProUser,
  router
}), {
  label: "Sell",
  path: parsedIsbns.length > 1 ? `${PATH.BULK_COMPARISON}${generateQueryIsbnUrl({
    isbns: parsedIsbns
  })}` : searchValue.length ? getSearchQueryString({
    searchValue,
    activeSearchTab: SELL_STATE
  }) : router.asPath.includes(PATH.BOOK) ? `${PATH.BOOK}/${router.query.slug}${getQueryParamsByTab(SELL_STATE)}` : PATH.MAIN,
  show: !isMainPage,
  isActive: router.asPath.includes(PATH.BOOK) && searchTab === SELL_STATE || router.asPath.includes(PATH.BULK_COMPARISON),
  onlyOnDesktopShow: true,
  shallow: true,
  id: "Sell menu link"
}, {
  label: "Buy",
  path: searchValue.length ? getSearchQueryString({
    searchValue,
    activeSearchTab: BUY_STATE
  }) : router.asPath.includes(PATH.BOOK) ? `${PATH.BOOK}/${router.query.slug}${getQueryParamsByTab(BUY_STATE)}` : PATH.BUY,
  show: !isMainPage,
  isActive: router.asPath.includes(PATH.BOOK) && searchTab === BUY_STATE,
  onlyOnDesktopShow: true,
  shallow: true,
  id: "Buy menu link"
}, {
  label: "Rent",
  path: searchValue.length ? getSearchQueryString({
    searchValue,
    activeSearchTab: RENT_STATE
  }) : router.asPath.includes(PATH.BOOK) ? `${PATH.BOOK}/${router.query.slug}${getQueryParamsByTab(RENT_STATE)}` : PATH.RENT,
  show: !isMainPage,
  isActive: router.asPath.includes(PATH.BOOK) && searchTab === RENT_STATE,
  onlyOnDesktopShow: true,
  shallow: !router.asPath.includes("isbn13"),
  id: "Rent menu link"
}];
export const getNavList = ({
  searchValue,
  router,
  isProUser,
  isMainPage,
  searchTab,
  parsedIsbns
}) => {
  const config = navConfig({
    isProUser,
    isMainPage,
    router,
    searchTab,
    searchValue,
    parsedIsbns
  });
  return config.map(({
    label,
    path,
    show = true,
    onlyOnMobileShow = false,
    isActive,
    shallow,
    id
  }) => {
    if (!show || onlyOnMobileShow) return null;
    return <S.NavItem key={label}>
                    <Link passHref href={path} prefetch={false} shallow={Boolean(shallow)}>
                        <S.NavLink data-test-id={id} className={isActive && "active"}>
                            {label}
                        </S.NavLink>
                    </Link>
                </S.NavItem>;
  });
};
export const getDesktopDropdownProMenu = ({
  show,
  config
}) => {
  return <S.NavMenuListWrapper show={show}>
            <S.NavMenuList>
                {config.subMenuConfig.show && config.subMenuConfig.links.map(({
        path,
        label,
        isActive,
        id
      }, index) => <S.NavLinkItem key={index}>
                            <Link passHref href={path} prefetch={false}>
                                <S.NavLink data-test-id={id} className={isActive && "active"}>
                                    {label}
                                </S.NavLink>
                            </Link>
                        </S.NavLinkItem>)}
            </S.NavMenuList>
        </S.NavMenuListWrapper>;
};