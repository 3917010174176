export const FETCH_AUTH_SUCCESS = "FETCH_AUTH_SUCCESS";
export const FETCH_AUTH_ERROR = "FETCH_AUTH_ERROR";

export const SET_USER_SETTINGS = "SET_USER_SETTINGS";

export const LOGIN_AUTH = "LOGIN_AUTH";
export const LOGIN_AUTH_SUCCESS = "LOGIN_AUTH_SUCCESS";
export const LOGIN_AUTH_ERROR = "LOGIN_AUTH_ERROR";

export const LOGOUT_AUTH = "LOGOUT_AUTH";
export const LOGOUT_AUTH_SUCCESS = "LOGOUT_AUTH_SUCCESS";
export const LOGOUT_AUTH_ERROR = "LOGOUT_AUTH_ERROR";

export const SIGN_UP_AUTH = "SIGN_UP_AUTH";
export const SIGN_UP_AUTH_SUCCESS = "SIGN_UP_AUTH_SUCCESS";
export const SIGN_UP_AUTH_ERROR = "SIGN_UP_AUTH_ERROR";

export const RESET_AUTH = "RESET_AUTH";
export const RESET_AUTH_SUCCESS = "RESET_AUTH_SUCCESS";
export const RESET_AUTH_ERROR = "RESET_AUTH_ERROR";

export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS";
export const UPDATE_USER_DATA_ERROR = "UPDATE_USER_DATA_ERROR";

export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_USER_PASSWORD_ERROR = "UPDATE_USER_PASSWORD_ERROR";

export const DECREMENT_WATCHLIST_COUNTER = "DECREMENT_WATCHLIST_COUNTER";
export const SET_WATCHLIST = "SET_WATCHLIST";
export const SET_WATCHLIST_COUNTER_ERROR = "SET_WATCHLIST_COUNTER_ERROR";

export const RESET_LOGIN_PASSED = "RESET_LOGIN_PASSED";

export const SET_AFTER_AUTH_CALLBACK = "SET_AFTER_AUTH_CALLBACK";
