import http from "api";

import {
    addSuccessNotification,
    addErrorNotification,
    updateNotification
} from "common/notifications";

import { MAIN, ACCOUNT_PASSWORD } from "common/constants/pathRoutes";
import { FORM } from "common/constants/notificationContainerTypes";
import { appNotifications } from "common/constants/notifications";

import { SHOW_RECAPTCHA_V2, UNSET_AUTH_DATA } from "components/forms/SignUp/reducers/actionTypes";

import {
    FETCH_AUTH_SUCCESS,
    FETCH_AUTH_ERROR,
    LOGIN_AUTH,
    LOGIN_AUTH_SUCCESS,
    LOGIN_AUTH_ERROR,
    LOGOUT_AUTH,
    LOGOUT_AUTH_SUCCESS,
    LOGOUT_AUTH_ERROR,
    SIGN_UP_AUTH,
    SIGN_UP_AUTH_SUCCESS,
    SIGN_UP_AUTH_ERROR,
    RESET_AUTH,
    RESET_AUTH_SUCCESS,
    RESET_AUTH_ERROR,
    UPDATE_USER_DATA,
    UPDATE_USER_DATA_SUCCESS,
    UPDATE_USER_DATA_ERROR,
    UPDATE_USER_PASSWORD,
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_PASSWORD_ERROR,
    RESET_LOGIN_PASSED
} from "../reducers/actionTypes";

import { RESET_WATCHLIST_DATA } from "../../App/reducers/actionTypes";

import { getSignUpRecaptchaTokenV2, isSignUpRecaptchaEnabled } from "common/utils";

const {
    LOGIN_SUCCESS_NOTIFICATION,
    RESET_PASS_SUCCESS_NOTIFICATION,
    LOGOUT_SUCCESS_NOTIFICATION,
    UPDATE_USER_DATA_SUCCESS_NOTIFICATION,
    UPDATE_USER_PASSWORD_SUCCESS_NOTIFICATION
} = appNotifications;

export const getAuth = async ({ dispatch, isLoginPassed, afterAuthCallback }) => {
    try {
        const response = await http.getAuth();

        dispatch({ type: FETCH_AUTH_SUCCESS, payload: response });

        if (isLoginPassed && afterAuthCallback) {
            afterAuthCallback({ user: response.user });
        }

        dispatch({ type: RESET_LOGIN_PASSED });

        if (response.isAuth) {
            window.dataLayer.push({ event: "userLoggedIn" });
        }
    } catch (error) {
        dispatch({ type: FETCH_AUTH_ERROR });

        addErrorNotification({ msg: error.message });
    }
};

export const linkLoginAuth = async ({ dispatch, data, history }) => {
    try {
        dispatch({ type: LOGIN_AUTH });

        const response = await http.loginAuth({ data });

        dispatch({
            type: LOGIN_AUTH_SUCCESS,
            payload: response
        });

        history.push(ACCOUNT_PASSWORD);

        addSuccessNotification({ msg: LOGIN_SUCCESS_NOTIFICATION });
    } catch (error) {
        dispatch({ type: LOGIN_AUTH_ERROR });

        history.push(MAIN);

        addErrorNotification({
            msg: error.message
        });
    }
};

export const loginAuth = async ({ dispatch, handleClose, data, errorNotification }) => {
    try {
        dispatch({ type: LOGIN_AUTH });

        const response = await http.loginAuth({ data });

        dispatch({
            type: LOGIN_AUTH_SUCCESS,
            payload: response
        });

        handleClose();
        window.dataLayer.push({ event: "logInSuccess" });
        addSuccessNotification({ msg: LOGIN_SUCCESS_NOTIFICATION });
    } catch (error) {
        dispatch({ type: LOGIN_AUTH_ERROR });

        if (!errorNotification.current) {
            errorNotification.current = addErrorNotification({
                msg: error.message,
                containerId: FORM
            });
        } else {
            updateNotification(errorNotification.current, {
                msg: error.message,
                containerId: FORM
            });
        }
    }
};

export const signUpAuth = async ({
    authDispatch,
    dispatch,
    handleClose,
    data,
    recaptchaV3Token,
    errorNotification,
    handleSignUpProModal
}) => {
    try {
        authDispatch({ type: SIGN_UP_AUTH });

        const response = await http.signUpAuth({
            data,
            tokens: {
                token: recaptchaV3Token,
                ...getSignUpRecaptchaTokenV2()
            }
        });

        authDispatch({ type: SIGN_UP_AUTH_SUCCESS, payload: response });
        dispatch({ type: UNSET_AUTH_DATA });

        window.dataLayer.push({ event: "accountCreated" });

        await loginAuth({ dispatch: authDispatch, data, handleClose, errorNotification });

        if (data.shouldSubscribe) {
            handleSignUpProModal();
        }
    } catch (error) {
        authDispatch({ type: SIGN_UP_AUTH_ERROR, payload: error.message });

        if (error.status === 403 && error.extra.triggerCaptcha && isSignUpRecaptchaEnabled) {
            dispatch({
                type: SHOW_RECAPTCHA_V2,
                payload: true
            });

            return;
        }

        if (!errorNotification.current) {
            errorNotification.current = addErrorNotification({
                msg: error.message,
                containerId: FORM
            });
        } else {
            updateNotification(errorNotification.current, {
                msg: error.message,
                containerId: FORM
            });
        }
    }
};

export const resetPassAuth = async ({ dispatch, handleClose, data, errorNotification }) => {
    try {
        dispatch({ type: RESET_AUTH });

        await http.resetAuth({ data });

        dispatch({ type: RESET_AUTH_SUCCESS });

        handleClose();

        addSuccessNotification({
            msg: RESET_PASS_SUCCESS_NOTIFICATION
        });
    } catch (error) {
        dispatch({ type: RESET_AUTH_ERROR, payload: error.message });

        if (!errorNotification.current) {
            errorNotification.current = addErrorNotification({
                msg: error.message,
                containerId: FORM
            });
        } else {
            updateNotification(errorNotification.current, {
                msg: error.message,
                containerId: FORM
            });
        }
    }
};

export const logoutAuth = async ({ dispatch, history, appDispatch }) => {
    try {
        dispatch({ type: LOGOUT_AUTH });

        await http.logoutAuth();

        dispatch({ type: LOGOUT_AUTH_SUCCESS });
        appDispatch && appDispatch({ type: RESET_WATCHLIST_DATA });

        history.push(MAIN);

        addSuccessNotification({ msg: LOGOUT_SUCCESS_NOTIFICATION });
    } catch (error) {
        dispatch({ type: LOGOUT_AUTH_ERROR });

        addErrorNotification({ msg: error.message });
    }
};

export const updateUserData = async ({ dispatch, id, handleClose, data, errorNotification }) => {
    try {
        dispatch({ type: UPDATE_USER_DATA });

        const response = await http.updateUserData({ id, data });

        dispatch({ type: UPDATE_USER_DATA_SUCCESS, payload: response });

        handleClose && handleClose();

        addSuccessNotification({ msg: UPDATE_USER_DATA_SUCCESS_NOTIFICATION });
    } catch (error) {
        dispatch({ type: UPDATE_USER_DATA_ERROR, payload: error });

        if (errorNotification) {
            if (!errorNotification.current) {
                errorNotification.current = addErrorNotification({
                    msg: error.message,
                    containerId: FORM
                });
            } else {
                updateNotification(errorNotification.current, {
                    msg: error.message,
                    containerId: FORM
                });
            }
        } else {
            addErrorNotification({ msg: error.message });
        }
    }
};

export const updateUserPassword = async ({
    dispatch,
    id,
    handleClose,
    data,
    errorNotification
}) => {
    try {
        dispatch({ type: UPDATE_USER_PASSWORD });

        const response = await http.updateUserPassword({ id, data });

        dispatch({ type: UPDATE_USER_PASSWORD_SUCCESS, payload: response });

        handleClose && handleClose();

        addSuccessNotification({ msg: UPDATE_USER_PASSWORD_SUCCESS_NOTIFICATION });
    } catch (error) {
        dispatch({ type: UPDATE_USER_PASSWORD_ERROR, payload: error });

        if (errorNotification) {
            if (!errorNotification.current) {
                errorNotification.current = addErrorNotification({
                    msg: error.message,
                    containerId: FORM
                });
            } else {
                updateNotification(errorNotification.current, {
                    msg: error.message,
                    containerId: FORM
                });
            }
        } else {
            addErrorNotification({ msg: error.message });
        }
    }
};
