import React from "react";
import Head from "next/head";

const BookstoresLandingSeo = () => (
    <Head>
        <title key="title">
            College Bookstores Directory - Campus Bookstores in the US and CA | BookScouter.com
        </title>
        <meta
            name="description"
            key="description"
            content={
                // eslint-disable-next-line max-len
                "We've collected an extensive list of campus college bookstores across the country (sorted by state). Here you can find college bookstores' contact information and links to their official websites."
            }
        />
    </Head>
);

export default BookstoresLandingSeo;
