import React from "react";
import Head from "next/head";

import * as PATH from "common/constants/pathRoutes";

export const ReviewsSeo = () => (
    <Head>
        <title key="title">
            {"BookScouter Reviews - What Users Really Think of BookScouter.com"}
        </title>
        <meta
            name="description"
            key="description"
            content={
                "View BookScouter reviews to discover if BookScouter is legit and reliable. " +
                "Leave a review and share your experience in selling, buying, and renting " +
                "textbooks and used books with Bookscouter.com."
            }
        />
        <meta
            property="og:title"
            key="og:title"
            content="BookScouter Reviews - What Users Really Think of BookScouter.com"
        />
        <meta
            property="og:description"
            key="og:description"
            content={
                "View BookScouter reviews to discover if BookScouter is legit and reliable. " +
                "Leave a review and share your experience in selling, buying, and renting " +
                "textbooks and used books with Bookscouter.com."
            }
        />
        <meta property="og:image" key="og:image" content="/bookscouter-og-icon.png" />
        <meta
            property="og:url"
            key="og:url"
            content={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.REVIEWS}`}
        />
    </Head>
);
