import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as ArrowIcon } from "public/icons/new-arrow.svg";
import * as S from "./Dropdown.styled";
const Dropdown = ({
  title,
  children,
  opened
}) => {
  const [isOpen, setIsOpen] = useState(opened);
  const [maxHeight, setMaxHeight] = useState(0);
  const menuRef = useRef(null);
  const handleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  useEffect(() => {
    if (menuRef.current) {
      setMaxHeight(menuRef.current.scrollHeight);
    }
  }, [menuRef.current]);
  useEffect(() => {
    const listener = () => {
      if (menuRef.current) {
        setMaxHeight(menuRef.current.scrollHeight);
      }
    };
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [menuRef.current]);
  return <S.Container>
            <S.TitleWrapper onClick={handleOpen} isOpen={isOpen} aria-label={`Dropdown title ${title}`}>
                <S.Title isOpen={isOpen}>
                    {title}
                    <ArrowIcon />
                </S.Title>
            </S.TitleWrapper>
            <S.ChildrenContainer isOpen={isOpen} maxHeight={maxHeight}>
                <S.Childrens ref={menuRef}>{children}</S.Childrens>
            </S.ChildrenContainer>
        </S.Container>;
};
Dropdown.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  opened: PropTypes.bool
};
Dropdown.defaultProps = {
  opened: false
};
export default memo(Dropdown);