import { colors } from "common/styles/config";

export const getKeyframeConfigList = ({
    colorStart = "#d6d8dd",
    colorEnd = colors.mainOrange
} = {}) => {
    const keyframeConfigList = [];
    const totalBooks = 9;

    for (let i = 1; i <= totalBooks; i++) {
        const pctRange = 100 / totalBooks;
        const pctEnd = pctRange * i;
        const pctStart = pctEnd - pctRange;
        const keyframeDimension = "%";

        keyframeConfigList.push({
            pctStart: `${pctStart}${keyframeDimension}`,
            pctEnd: `${pctEnd}${keyframeDimension}`,
            id: i
        });
    }

    return keyframeConfigList.reduce((acc, { id, pctStart, pctEnd }) => {
        return {
            ...acc,
            [`@keyframes book_${id}`]: {
                [`${pctStart}, from`]: { fill: colorStart },
                [`${pctEnd}, to`]: { fill: colorEnd }
            },
            [`& .book:nth-child(${id})`]: {
                animationName: `book_${id}`
            }
        };
    }, {});
};
