import { BOOK } from "common/constants/pathRoutes";
import { BUY_STATE, SELL_STATE } from "common/constants/searchTypes";

import { memberArrayFormatterFromApi } from "../common";
import { bookBaseFieldsFormatterFromApi } from "../book";

export const recentBooksFormatterFromApi = (data) =>
    memberArrayFormatterFromApi(data).map(recentBookFormatterFromApi);

export const recentBookFormatterFromApi = ({ book, maxPrice }) => ({
    price: parseFloat(maxPrice).toFixed(2),
    buyLink: `${BOOK}/${book.isbn13}-${book.slug}?type=${BUY_STATE}`,
    sellLink: `${BOOK}/${book.isbn13}-${book.slug}?type=${SELL_STATE}`,
    book: bookBaseFieldsFormatterFromApi(book)
});
