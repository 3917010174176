import * as PATH from "common/constants/pathRoutes";
import Head from "next/head";
import React from "react";

export const PrivacySeo = () => (
    <Head>
        <title key="title">{"Privacy Policy | BookScouter.com"}</title>
        <link
            rel="canonical"
            key="canonical"
            href={`${process.env.NEXT_PUBLIC_BASE_URL}${PATH.PRIVACY}`}
        />
        <meta
            name="description"
            key="description"
            content={"Read more about BookScouter's Privacy Policy."}
        />
        <meta property="og:title" key="og:title" content={""} />
    </Head>
);
