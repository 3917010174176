import { upperFirst } from "common/utils";

const DEFAULT_ERROR_MESSAGE = "Something went wrong. Please try again later.";
const DEFAULT_ERROR_STATUS = 500;

const getErrorMessage = (message = DEFAULT_ERROR_MESSAGE, status = DEFAULT_ERROR_STATUS) => {
    console.error(JSON.stringify({ status, message }, null, 4));

    return message;
};

export const errorHandler = (error) => {
    try {
        const { response, message: errorMessage } = error;

        // default error
        if (!response) {
            const message = getErrorMessage(errorMessage);

            return {
                status: DEFAULT_ERROR_STATUS,
                message
            };
        }
        if (
            response.status === 401 &&
            (response.data?.detail?.toLowerCase()?.includes("token") ||
                response.data?.detail?.toLowerCase()?.includes("invalid credentials"))
        ) {
            const message = getErrorMessage(
                response.data?.detail || "Unauthorized",
                response.status
            );

            setTimeout(() => (window.location.href = process.env.NEXT_PUBLIC_BASE_URL), 2000);

            return {
                status: response.status,
                message
            };
        }

        // captcha error case
        if (response.status === 403) {
            const message = getErrorMessage(response.data["hydra:description"], response.status);

            return {
                status: response.status,
                message,
                extra: {
                    triggerCaptcha: true
                }
            };
        }

        // TODO: check that responseJSON includes in error response
        // delete cases with responseJSON if it not used
        if (response.responseJSON?.detail) {
            const message = getErrorMessage(response.responseJSON.detail, response.status);

            return {
                message,
                status: response.status || DEFAULT_ERROR_STATUS
            };
        } else if (response.responseJSON?.violations) {
            const compoundMessage = response.responseJSON.violations
                .map((violation) => {
                    return `${upperFirst(
                        violation.propertyPath
                    )}: ${violation.message.toLowerCase()}`;
                })
                .join(",");

            const message = getErrorMessage(compoundMessage, response.status);

            return {
                message,
                status: response.status || DEFAULT_ERROR_STATUS
            };
        } else if (response.responseJSON && response.responseJSON["hydra:description"]) {
            const message = getErrorMessage(
                response.responseJSON["hydra:description"],
                response.status
            );

            return {
                message,
                status: response.status || DEFAULT_ERROR_STATUS
            };
        } else if (response.data?.detail) {
            const message = getErrorMessage(response.data.detail, response.status);

            return {
                message,
                status: response.status || DEFAULT_ERROR_STATUS
            };
        } else if (response.data?.violations) {
            const compoundMessage = response.data.violations
                .map((violation) => {
                    return `${upperFirst(
                        violation.propertyPath
                    )}: ${violation.message.toLowerCase()}`;
                })
                .join(",");

            const message = getErrorMessage(compoundMessage, response.status);

            return {
                message,
                status: response.status || DEFAULT_ERROR_STATUS
            };
        } else if (response.data && response.data["hydra:description"]) {
            const message = getErrorMessage(response.data["hydra:description"], response.status);

            return {
                message,
                status: response.status || DEFAULT_ERROR_STATUS
            };
        } else if (response.status === 500) {
            const message = getErrorMessage(DEFAULT_ERROR_MESSAGE, response.status);

            return {
                message,
                status: response.status || DEFAULT_ERROR_STATUS
            };
        }

        getErrorMessage(DEFAULT_ERROR_MESSAGE, response.status);

        return { status: DEFAULT_ERROR_STATUS, message: DEFAULT_ERROR_MESSAGE };
    } catch (error) {
        console.error(error);
    }
};

export const makeMultipleRequest = async ({ fetchCountLimit = 5, requestHandler, requestData }) => {
    const response = await new Promise((resolve) => {
        let fetchCount = 0;

        const makeRequest = async () => {
            fetchCount++;

            if (fetchCount === fetchCountLimit) {
                return;
            }

            const response = await requestHandler({ ...requestData });

            if (response?.data.status === "SUCCESS" || fetchCount === fetchCountLimit) {
                resolve(response);
                return;
            }

            setTimeout(makeRequest, 1200);
        };

        makeRequest();
    });

    return response;
};
