import React, { useMemo, useCallback } from "react";
import { useRouter } from "next/router";
import { isPresentValue } from "common/utils";
import ModalTypes from "common/constants/modalTypes";
import { useModalContext } from "contexts/Modal";
import { buildAppLinks, renderSubLinks, renderSections, renderSocialLinks, buildSections, buildSocialLinks } from "./utils";
import * as S from "./Footer.styled";

/**
 * Application footer
 */

const Footer = () => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const router = useRouter();
  const {
    modalAction
  } = useModalContext();
  const handleContactModal = useCallback(event => {
    if (isPresentValue(event)) {
      event.preventDefault();
    }
    modalAction({
      type: ModalTypes.CONTACT,
      title: "Leave a Comment"
    });
  }, []);
  const sections = useMemo(() => buildSections({
    handleContactModal,
    path: router.asPath
  }), [router.asPath]);
  const appLinks = useMemo(() => renderSocialLinks(buildAppLinks()), []);
  const socialLinks = useMemo(() => renderSocialLinks(buildSocialLinks()), []);
  const subLinks = useMemo(() => renderSubLinks(), []);
  return <>
            <S.FooterBase data-test-id="Footer-FooterBase">
                <S.FooterContent>
                    <S.FooterMainLinks>
                        <S.FooterPagesLinks>{renderSections(sections)}</S.FooterPagesLinks>
                        <S.FooterAppLinks aria-label="Mobile application links">
                            {appLinks}
                        </S.FooterAppLinks>
                    </S.FooterMainLinks>
                    <S.LinksSeparator />
                    <S.FooterBottomContent>
                        <S.FooterSocialLinks>
                            <S.FooterSocialList aria-label="Social networks links">
                                {socialLinks}
                            </S.FooterSocialList>
                        </S.FooterSocialLinks>
                        <S.FooterCopyrightContent>
                            <S.FooterLinkSubList aria-label="Terms and Privacy links">
                                {subLinks}
                            </S.FooterLinkSubList>
                            <S.SearchRecaptcha>
                                {"This site is protected by reCAPTCHA and the Google "}
                                <S.RecaptchaLink href="https://policies.google.com/privacy" rel="nofollow noopener noreferrer" target="_blank">
                                    Privacy Policy
                                </S.RecaptchaLink>
                                {" and "}
                                <S.RecaptchaLink href="https://policies.google.com/terms" rel="nofollow noopener noreferrer" target="_blank">
                                    Terms of Service
                                </S.RecaptchaLink>
                                {" apply."}
                            </S.SearchRecaptcha>
                        </S.FooterCopyrightContent>
                    </S.FooterBottomContent>
                    <S.CopyrightText data-test-id="Footer-CopyrightText">
                        {`Copyright © 2007-${currentYear} BOOKSCOUTER LLC`}
                    </S.CopyrightText>
                </S.FooterContent>
            </S.FooterBase>
        </>;
};
export default Footer;