const ModalTypes = {
    CONTACT: "contact",
    LOG_IN: "logIn",
    SIGN_UP: "signUp",
    SIGN_UP_PRO: "signUpPro",
    RESET_PASSWORD: "resetPassword",
    UPDATE_PASSWORD: "updatePassword",
    CANCELLATION: "cancellation",
    MULTIPLE_ISBNS: "multipleIsbns",
    VENDORS_FEEDBACK: "vendorsFeedback",
    REPORT_PRICING_ISSUE: "reportPricingIssue",
    PRICE_HISTORY: "priceHistory",
    PRICE_HISTORY_BUY: "priceHistoryBuy",
    PRICE_ALERT: "priceAlert",
    GRID_LIST_REDIRECT: "gridListRedirect",
    MULTIPLE_WATCHLIST: "multipleWatchlist",
    CHANGE_PAYMENT: "changePayment",
    REPORT_SELLER: "reportSeller",
    DEALS_FILTER_VENDORS: "dealsSelectVendors",
    EMAIL_SETTINGS: "emailSettings",
    DEALS_DETAILS: "dealsDetails",
    CLEAR_RECENTLY_HISTORY: "clearRecentlyHistory",
    BUY_VS_RENT: "buyVsRent"
};

export default ModalTypes;
