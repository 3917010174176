const DEALS = "deals";
const BULK_LOOKUP = "bulk-lookup";
const PRICE_HISTORY = "historic";
const HIGH_VALUE = "high-value";
const NEWS_PANEL = "news-panel";
const HEADER = "header";
const SCHOLARSHIP_ELIGIBLE = "scholarship-eligible";
const DEALS_TABLE = "deals-table";
const HIGH_VALUE_TABLE = "high-value-table";
const HISTORIC_PRICING_TABLE = "historic-pricing-table";

const Anchors = {
    DEALS,
    BULK_LOOKUP,
    PRICE_HISTORY,
    HIGH_VALUE,
    NEWS_PANEL,
    HEADER,
    SCHOLARSHIP_ELIGIBLE,
    DEALS_TABLE,
    HIGH_VALUE_TABLE,
    HISTORIC_PRICING_TABLE
};

export default Anchors;
