import { APP, FORM } from "common/constants/notificationContainerTypes";

export const appNotificationConfig = {
    position: "bottom-left",
    autoClose: 7000,
    newestOnTop: true,
    enableMultiContainer: true,
    containerId: APP
};

export const formNotificationConfig = {
    enableMultiContainer: true,
    containerId: FORM,
    position: "top-left",
    hideProgressBar: true,
    autoClose: false,
    closeOnClick: false,
    draggable: false,
    closeButton: false,
    toastClassName: `Toast_${FORM}`
};
