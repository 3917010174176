import getQueriesConfig from "./config";

import { BUY_STATE } from "common/constants/searchTypes";

import {
    loginFormatterToApi,
    linkLoginFormatterToApi,
    loginFormatterFromApi,
    resetPassFormatterToApi,
    getAuthUserFormatterFromApi,
    userDataFormatterToApi,
    userDataFormatterFromApi,
    signUpFormatterToApi,
    userSubscriptionsFormatterFromApi,
    userSubscriptionsHistoryFormatterFromApi,
    userSettingsFormatterFromApi,
    userSettingsFormatterToApi
} from "./formatters/auth";
import {
    vendorsSellFormatterFromApi,
    vendorFeedbackFormatterFromApi,
    vendorsFeedbackFormatterToApi,
    vendorsGridViewSellFormatterFromApi,
    vendorDetailsFormatterFromApi,
    vendorsFormatterFromApi,
    vendorFormatterFromApi,
    vendorsBulkLookupFormatterFromApi
} from "./formatters/vendors";
import { booksFormatterFromApi } from "./formatters/search";
import { contactFormatterToApi } from "./formatters/contact";
import { subscriptionFormatterToApi } from "./formatters/signUpPro";
import {
    bookPricesFormatterFromApi,
    bookFormatterFromApi,
    bookSalesFormatterFromApi,
    bookSellersFormatterFromApi,
    bookEditionsFormatterFromApi
} from "./formatters/book";
import { watchlistFormatterFromApi, watchlistItemFormatterFromApi } from "./formatters/watchlist";
import {
    priceHistoryFormatterFromApi,
    priceHistoryAmazonBuyPrice,
    priceHistoryAmazonSalesRank,
    bestPricesHistoryFormatterFromApi
} from "./formatters/priceHistory";
import { recentBooksFormatterFromApi } from "./formatters/main/";
import { dealItemFormatter, dealsFormatterFromApi } from "./formatters/deals";
import { shortenedBooksFormatterFromApi } from "./formatters/shortenedBook";
import { sellersBlacklistFormatterFromApi } from "./formatters/sellers";
import {
    bulkJobFormatterFromApi,
    bulkJobTargetPriceFormatterFromApi
} from "./formatters/bulkUpload";
import { memberArrayFormatterFromApi } from "./formatters/common";
import {
    categoriesBooksFromApi,
    categoriesFormatterFromApi,
    categoryFormatterFromApi
} from "./formatters/categories";

import { isRecaptchaEnabled, isSignUpRecaptchaEnabled } from "common/utils";
import { buyVsRentCalculatorFormatter } from "./formatters/buyVsRentCalculator";

const createBaseRequests = () => {
    const { clientQueryConfig } = getQueriesConfig({
        url: process.env.NEXT_PUBLIC_API_URL,
        options: {
            withCredentials: true
        }
    });

    const { serverQueryConfig } = getQueriesConfig({
        url: process.env.NEXT_PUBLIC_API_URL
    });

    const getBookData = async ({ isbn, params } = {}) => {
        const response = await serverQueryConfig.get(`v4/books/${isbn}`, { params });

        return bookFormatterFromApi(response.data);
    };

    const getBooksData = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("v4/prices/recent/sell", { params });

        return recentBooksFormatterFromApi(response.data);
    };

    const getHighResaleBooks = async () => {
        const response = await clientQueryConfig.get("/v4/historic/sell/recent/peek");

        return recentBooksFormatterFromApi(response.data);
    };

    const getVendors = async ({ params, vendorsForGridView, allVendors, allowBulk } = {}) => {
        const response = await clientQueryConfig.get("v4/vendors", { params });

        if (vendorsForGridView) {
            return vendorsGridViewSellFormatterFromApi(response.data);
        }

        if (allVendors) {
            return vendorsFormatterFromApi(response.data);
        }

        if (allowBulk) {
            return vendorsBulkLookupFormatterFromApi(response.data);
        }

        return params.buySell === BUY_STATE
            ? vendorsFormatterFromApi(response.data)
            : vendorsSellFormatterFromApi(response.data);
    };

    const sendComment = async ({ data, params } = {}) => {
        return await clientQueryConfig.post("v4/contacts", contactFormatterToApi(data), { params });
    };

    const getBooksBySearch = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("v4/search/new", {
            params
        });

        return booksFormatterFromApi(response.data);
    };

    const getAuth = async ({ params, headers } = {}) => {
        const response = await clientQueryConfig.get("v4/auth", { params, headers });

        return getAuthUserFormatterFromApi(response.data);
    };

    const loginAuth = async ({ data, params } = {}) => {
        const response = await clientQueryConfig.post(
            "v4/auth",
            data.isLoginLink ? linkLoginFormatterToApi(data) : loginFormatterToApi(data),
            {
                params
            }
        );

        return loginFormatterFromApi(response.data);
    };

    const logoutAuth = async ({ params } = {}) => {
        return await clientQueryConfig.delete("v4/auth", { params });
    };

    const signUpAuth = async ({ data, params, tokens } = {}) => {
        const response = await clientQueryConfig.post("v4/users", signUpFormatterToApi(data), {
            params,
            headers: isSignUpRecaptchaEnabled
                ? {
                      token: tokens.token,
                      tokenV2: tokens.tokenV2
                  }
                : {}
        });

        return userDataFormatterFromApi(response.data);
    };

    const resetAuth = async ({ data, params } = {}) => {
        return await clientQueryConfig.post("v4/reset_password", resetPassFormatterToApi(data), {
            params
        });
    };

    const updateUserData = async ({ id, data } = {}) => {
        const response = await clientQueryConfig.put(
            `v4/users/${id}`,
            userDataFormatterToApi(data)
        );

        return userDataFormatterFromApi(response.data);
    };

    const updateDealsSettings = async ({ id, data } = {}) => {
        const response = await clientQueryConfig.put(
            `v4/users/${id}`,
            userSettingsFormatterToApi(data)
        );

        return userDataFormatterFromApi(response.data);
    };

    const updateUserPassword = async ({ data, params } = {}) => {
        const response = await clientQueryConfig.post("v4/users/password", data, {
            params
        });

        return userDataFormatterFromApi(response.data);
    };

    const sendSubscription = async ({ data, params } = {}) => {
        const response = await clientQueryConfig.post(
            "v4/subscriptions",
            subscriptionFormatterToApi(data),
            { params }
        );

        return response;
    };

    const cancelSubscription = async ({ userId } = {}) => {
        await clientQueryConfig.delete(`v4/subscriptions/${userId}`);
    };

    const cancelSubscriptionFeedback = async ({ data } = {}) => {
        const response = await clientQueryConfig.post("v4/cancellation_feedback", {
            ...data,
            reason: data.customReason ? data.reasonOther : data.reason
        });

        return response;
    };

    const updateUserSettings = async ({ userId, data } = {}) => {
        const response = await clientQueryConfig.put(
            `v4/users/${userId}`,
            userSettingsFormatterToApi(data)
        );

        return userSettingsFormatterFromApi(response.data);
    };

    const getWatchList = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("v4/watches", { params });

        return watchlistFormatterFromApi(response.data);
    };

    const addToWatchlist = async ({ data } = {}) => {
        const response = await clientQueryConfig.post("v4/watches", data);

        return watchlistItemFormatterFromApi(response.data);
    };

    const removeBookFromWatchlist = async ({ id } = {}) => {
        return await clientQueryConfig.delete(`v4/watches/${id}`);
    };

    const updateBookWatchlist = async ({ id, data } = {}) => {
        return await clientQueryConfig.patch(`v4/watches/${id}`, data);
    };

    const getBookFromWatchlist = async ({ isbn } = {}) => {
        const response = await clientQueryConfig.get(`v4/watches/${isbn}`);

        return watchlistItemFormatterFromApi(response.data);
    };

    const getBookPrices = async ({ isbn, tokens, pageType, params } = {}) => {
        const response = await clientQueryConfig.get(`v4/prices/sell/${isbn}`, {
            params: {
                ...params
            },
            headers: isRecaptchaEnabled
                ? {
                      token: tokens.token,
                      tokenV2: tokens.tokenV2
                  }
                : {}
        });

        return bookPricesFormatterFromApi(response.data, pageType);
    };

    const getBookPricesBuyRent = async ({ isbn13, pageType, tokens } = {}) => {
        const response = await clientQueryConfig.get(`v4/prices/buy/${isbn13}`, {
            headers: isRecaptchaEnabled ? { token: tokens.token, tokenV2: tokens.tokenV2 } : {}
        });

        return bookPricesFormatterFromApi(response.data, pageType);
    };

    const getBookBuyVsRent = async ({ isbn, sellPeriod } = {}) => {
        const response = await clientQueryConfig.get(`v4/buy_vs_rent/${isbn}`, {
            params: {
                sell_period: sellPeriod
            }
        });

        return buyVsRentCalculatorFormatter({ data: response.data });
    };

    const getBookstoresCountByStates = async () => {
        const response = await clientQueryConfig.get("v4/bookstores/count-by-state");

        return memberArrayFormatterFromApi(response.data);
    };

    const getBookstoresByState = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("v4/bookstores", { params });

        return memberArrayFormatterFromApi(response.data);
    };

    const getVendorFeedback = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("v4/feedback", { params });

        return vendorFeedbackFormatterFromApi(response.data);
    };

    const sendVendorFeedback = async ({ data, params } = {}) => {
        return await clientQueryConfig.post("v4/feedback", vendorsFeedbackFormatterToApi(data), {
            params
        });
    };

    const getVendor = async ({ id, params } = {}) => {
        const response = await clientQueryConfig.get(`v4/vendors/${id}`, { params });

        return vendorFormatterFromApi(response.data);
    };

    const getVendorDetails = async ({ id, params } = {}) => {
        const response = await clientQueryConfig.get(`v4/vendors/${id}/details`, { params });

        return vendorDetailsFormatterFromApi(response.data);
    };

    const getPriceHistoryWeekly = async ({ params, withMeta } = {}) => {
        const response = await clientQueryConfig.get("v4/historic/sell/weekly", { params });

        return priceHistoryFormatterFromApi(response.data, withMeta);
    };

    const getPriceHistoryDaily = async ({ params, withMeta } = {}) => {
        const response = await clientQueryConfig.get("v4/historic/sell/daily", { params });

        return priceHistoryFormatterFromApi(response.data, withMeta);
    };

    const getUserSubscriptions = async ({ userId, params } = {}) => {
        const response = await clientQueryConfig.get(`v4/subscriptions/${userId}`, { params });

        return userSubscriptionsFormatterFromApi(response.data);
    };

    const getUserSubscriptionsHistory = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("v4/subscriptions/history", { params });

        return userSubscriptionsHistoryFormatterFromApi(response.data);
    };

    const getDeals = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("v4/deals", { params });

        return dealsFormatterFromApi(response.data);
    };

    const getDealDetails = async ({ params, id } = {}) => {
        const response = await clientQueryConfig.get(`v4/deals/${id}`, { params });

        return dealItemFormatter(response.data);
    };

    const getHistoricSellRecentBooks = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("v4/historic/sell/recent", { params });

        return shortenedBooksFormatterFromApi(response.data);
    };

    const getSellersBlacklist = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("v4/deals/ebay-sellers-blacklist", { params });

        return sellersBlacklistFormatterFromApi(response.data);
    };

    const sendSellerReport = async ({ data, params } = {}) => {
        return await clientQueryConfig.post("v4/deals/report-bad-ebay-seller", data, { params });
    };

    const sendBulkIsbns = async ({ data } = {}) => {
        const response = await clientQueryConfig.post("v4/bulk_jobs", data);

        return bulkJobFormatterFromApi(response.data);
    };

    const getBulkJob = async ({ id }) => {
        const response = await clientQueryConfig.get(`v4/bulk_jobs/${id}`);

        return bulkJobFormatterFromApi(response.data);
    };

    const getBulkJobTargetPriceReached = async ({ params }) => {
        const response = await clientQueryConfig.get("v4/bulk_jobs/target-price-reached", {
            params
        });

        return bulkJobTargetPriceFormatterFromApi(response.data);
    };

    const getSalesRank = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("v4/historic/sales_rank/latest", { params });

        return bookSalesFormatterFromApi(response.data);
    };

    const getSellersCount = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("v4/historic/buy/latest", { params });

        return bookSellersFormatterFromApi(response.data);
    };

    const getBookEditions = async ({ params, isbn } = {}) => {
        const response = await clientQueryConfig.get(`v4/books/${isbn}/editions`, { params });

        return bookEditionsFormatterFromApi(response.data);
    };

    const getAmazonBuyPrice = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("/v4/historic/buy/daily", { params });

        return memberArrayFormatterFromApi(response.data).map(priceHistoryAmazonBuyPrice);
    };

    const getAmazonSalesRank = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("/v4/historic/sales_rank/daily", { params });

        return memberArrayFormatterFromApi(response.data).map(priceHistoryAmazonSalesRank);
    };

    const getHistoryBestPrices = async ({ params, isbn } = {}) => {
        const response = await clientQueryConfig.get(`/v4/historic/best_price/${isbn}`, { params });

        return bestPricesHistoryFormatterFromApi(response.data);
    };

    const getTopAuthors = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("/v4/authors/top", { params });

        return categoriesFormatterFromApi(response.data);
    };

    const getBookFormats = async ({ params, isbn13 }) => {
        const response = await clientQueryConfig.get(`/v4/book_formats_details/${isbn13}`, {
            params
        });

        return response;
    };

    const getAuthors = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("/v4/authors", { params });

        return categoriesFormatterFromApi(response.data);
    };

    const getAuthor = async ({ params, slug } = {}) => {
        const response = await clientQueryConfig.get(`/v4/authors/${slug}`, { params });

        return categoryFormatterFromApi(response.data);
    };

    const getAuthorsBooks = async ({ params, slug } = {}) => {
        const response = await clientQueryConfig.get(`/v4/authors/${slug}/books`, { params });

        return categoriesBooksFromApi(response.data);
    };

    const getTopPublishers = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("/v4/publishers/top", { params });

        return categoriesFormatterFromApi(response.data);
    };

    const getPublishers = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("/v4/publishers", { params });

        return categoriesFormatterFromApi(response.data);
    };

    const getPublisher = async ({ params, slug } = {}) => {
        const response = await clientQueryConfig.get(`/v4/publishers/${slug}`, { params });

        return categoryFormatterFromApi(response.data);
    };

    const getPublishersBooks = async ({ params, slug } = {}) => {
        const response = await clientQueryConfig.get(`/v4/publishers/${slug}/books`, { params });

        return categoriesBooksFromApi(response.data);
    };

    const getCategories = async ({ params } = {}) => {
        const response = await clientQueryConfig.get("/v4/categories", { params });

        return categoriesFormatterFromApi(response.data);
    };

    const getCategory = async ({ params, id } = {}) => {
        const response = await clientQueryConfig.get(`/v4/categories/${id}`, { params });

        return categoryFormatterFromApi(response.data);
    };

    const getCategoriesBooks = async ({ params, id } = {}) => {
        const response = await clientQueryConfig.get(`/v4/categories/${id}/books`, { params });

        return categoriesBooksFromApi(response.data);
    };

    return {
        getBookData,
        getBooksData,
        sendComment,
        getBooksBySearch,
        getAuth,
        loginAuth,
        logoutAuth,
        signUpAuth,
        resetAuth,
        updateUserData,
        updateUserPassword,
        sendSubscription,
        cancelSubscription,
        updateUserSettings,
        getWatchList,
        addToWatchlist,
        removeBookFromWatchlist,
        getBookPrices,
        getVendorFeedback,
        sendVendorFeedback,
        getVendor,
        getVendorDetails,
        getPriceHistoryWeekly,
        getPriceHistoryDaily,
        getBookPricesBuyRent,
        getBookBuyVsRent,
        getUserSubscriptions,
        getUserSubscriptionsHistory,
        getVendors,
        updateBookWatchlist,
        cancelSubscriptionFeedback,
        getDeals,
        getDealDetails,
        updateDealsSettings,
        getHistoricSellRecentBooks,
        getSellersBlacklist,
        sendSellerReport,
        getBookstoresCountByStates,
        getBookstoresByState,
        sendBulkIsbns,
        getBulkJobTargetPriceReached,
        getBulkJob,
        getBookFromWatchlist,
        getSalesRank,
        getSellersCount,
        getBookEditions,
        getAmazonBuyPrice,
        getAmazonSalesRank,
        getHighResaleBooks,
        getHistoryBestPrices,
        getAuthors,
        getTopAuthors,
        getBookFormats,
        getAuthorsBooks,
        getAuthor,
        getTopPublishers,
        getPublishers,
        getPublisher,
        getPublishersBooks,
        getCategories,
        getCategory,
        getCategoriesBooks
    };
};

export default createBaseRequests;
